import { ArrowLeft, ArrowRight } from 'lucide-react'
import React, { useEffect } from 'react'
import { usePaginationLogic, useScrollArrow } from '../../../utils/hooks/usePaginationLogic'
import { searchProducts } from './SearchUtils'
const Pagination = ({ device, search, isMobileScreen, setIsShowArrowTop }) => {
	const totalPages = Math.ceil(device?.devices?.count / 10) || 0
	const currentPage = search.page

	const { getPageNumbers } = usePaginationLogic(totalPages, currentPage, isMobileScreen)

	useScrollArrow(setIsShowArrowTop)

	const handlePageClick = async page => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
		search.setPage(page)
		await searchProducts(search, device)
	}

	const ArrowButton = ({ direction, onClick }) => {
		const isLeft = direction === 'left'
		const isRight = direction === 'right'
		const ArrowIcon = isLeft ? ArrowLeft : isRight ? ArrowRight : null

		return (
			<li className='list-none mx-1.5'>
				<button
					onClick={onClick}
					className='px-2 py-1.5 rounded-md cursor-pointer tr bg-grey/30 h-9 hover:text-white hover:bg-accent'>
					{ArrowIcon && <ArrowIcon className='text-current w-5' />}
				</button>
			</li>
		)
	}

	return (
		<ul
			className={`mt-4 flex flex-row overflow-hidden justify-center items-center gap-1 p-0`}>
			{currentPage > 1 && (
				<ArrowButton
					direction={'left'}
					onClick={() => handlePageClick(currentPage - 1)}
				/>
			)}
			<div className='flex flex-row'>{getPageNumbers(isMobileScreen, handlePageClick)}</div>
			{currentPage < totalPages && (
				<ArrowButton
					direction={'right'}
					onClick={() => handlePageClick(currentPage + 1)}
				/>
			)}
		</ul>
	)
}

export default Pagination
