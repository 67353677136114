import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import CreateAnAdPage from './store/CreateAnAdPage'
import DeviceStore from './store/DeviceStore'
import SearchStore from './store/SearchStore'
import UserStore from './store/UserStore'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import LocationStore from "./store/LocationStore";

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<Context.Provider
		value={{
			user: new UserStore(),
			device: new DeviceStore(),
			dataSectionForAnAdPage: new CreateAnAdPage(),
			search: new SearchStore(),
			location: new LocationStore()
		}}>
		<App />
	</Context.Provider>,
)

serviceWorkerRegistration.register();
reportWebVitals();
