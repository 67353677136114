import React from 'react'
import { NavLink } from 'react-router-dom'

const CustomNavLink = ({ text, linkTo, isNavbar = false }) => {
	return (
		<NavLink
			to={linkTo}
			className={`flex ${
				isNavbar ? 'px-8 py-0 rounded-full' : 'px-4 py-2 min-w-36 text-sm rounded-md'
			} cursor-pointer text-gray-700 hover:bg-gray-100 items-center justify-center text-center`}>
			{text}
		</NavLink>
	)
}

export default CustomNavLink
