import { makeAutoObservable } from 'mobx'

export default class SearchStore {
	constructor() {
		this._stamp = ''
		this._model = ''
		this._generation = ''
		this._category = ''
		this._type = ''
		this._page = 1;

		this._cityFilter = ''
		this._conditionFilter = ''
		this._sortFilter = ''
		this._descFilter = ''
		this._maxPriceFilter = ''
		this._minPriceFilter = ''

		this._keyCounter = 0

		makeAutoObservable(this)
	}

	setStamp(stamp) {
		this._stamp = stamp
	}

	setModel(model) {
		this._model = model
	}

	setGeneration(generation) {
		this._generation = generation
	}

	setCategory(category) {
		this._category = category
	}

	setType(type) {
		this._type = type
	}

	setCityFilter(city) {
		this._cityFilter = city
	}

	setConditionFilter(conditionFilter) {
		this._conditionFilter = conditionFilter
	}

	setSortFilter(sortFilter) {
		this._sortFilter = sortFilter
	}

	setDescFilter(descFilter) {
		this._descFilter = descFilter
	}

	setMaxPriceFilter(maxPriceFilter) {
		this._maxPriceFilter = maxPriceFilter
	}

	setMinPriceFilter(minPriceFilter) {
		this._minPriceFilter = minPriceFilter
	}

	plusKeyCounter() {
		this._keyCounter += 1
	}

	get stamp() {
		return this._stamp
	}

	get model() {
		return this._model
	}

	get generation() {
		return this._generation
	}

	get category() {
		return this._category
	}

	get type() {
		return this._type
	}

	get cityFilter() {
		return this._cityFilter
	}

	get conditionFilter() {
		return this._conditionFilter
	}

	get sortFilter() {
		return this._sortFilter
	}

	get descFilter() {
		return this._descFilter
	}

	get maxPriceFilter() {
		return this._maxPriceFilter
	}

	get minPriceFilter() {
		return this._minPriceFilter
	}

	get keyCounter() {
		return this._keyCounter
	}

	get page() {
		return this._page;
	}

	setPage(number) {
		this._page = number;
	}
}
