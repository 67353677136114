// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_arrowButtonRight__L7eub {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border-radius: 50px;
  background-color: #e7e8e9;
  border: 1px solid black;
  width: 44px;
  height: 44px;
  transition: border 0.2s;
  z-index: 10;
}
.Slider_arrowButtonRight__L7eub .Slider_arrowSvg__LGNK3 {
  transition: stroke 0.2s;
}
.Slider_arrowButtonRight__L7eub:hover {
  border: 1px solid #ff6f19;
}
.Slider_arrowButtonRight__L7eub:hover .Slider_arrowSvg__LGNK3 {
  stroke: #ff6f19;
}

.Slider_arrowButtonLeft__a4Nd- {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  border-radius: 50px;
  background-color: #e7e8e9;
  border: 1px solid black;
  width: 44px;
  height: 44px;
  transition: border 0.2s;
  padding: 0 0 0 4px;
  z-index: 10;
}
.Slider_arrowButtonLeft__a4Nd- .Slider_arrowSvg__LGNK3 {
  transition: stroke 0.2s;
}
.Slider_arrowButtonLeft__a4Nd-:hover {
  border: 1px solid #ff6f19;
}
.Slider_arrowButtonLeft__a4Nd-:hover .Slider_arrowSvg__LGNK3 {
  stroke: #ff6f19;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Slider/Slider.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;AACF;AACE;EACE,uBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AACI;EACE,eAAA;AACN;;AAIA;EACE,eAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,uBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAGI;EACE,eAAA;AADN","sourcesContent":[".arrowButtonRight {\n  cursor: pointer;\n  position: absolute;\n  top: 50%;\n  right: 1rem;\n  transform: translateY(-50%);\n  border-radius: 50px;\n  background-color: #e7e8e9;\n  border: 1px solid black;\n  width: 44px;\n  height: 44px;\n  transition: border 0.2s;\n  z-index: 10;\n\n  .arrowSvg {\n    transition: stroke 0.2s;\n  }\n\n  &:hover {\n    border: 1px solid #ff6f19;\n    .arrowSvg {\n      stroke: #ff6f19;\n    }\n  }\n}\n\n.arrowButtonLeft {\n  cursor: pointer;\n  position: absolute;\n  top: 50%;\n  left: 1rem;\n  transform: translateY(-50%);\n  border-radius: 50px;\n  background-color: #e7e8e9;\n  border: 1px solid black;\n  width: 44px;\n  height: 44px;\n  transition: border 0.2s;\n  padding: 0 0 0 4px;\n  z-index: 10;\n\n  .arrowSvg {\n    transition: stroke 0.2s;\n  }\n\n  &:hover {\n    border: 1px solid #ff6f19;\n    .arrowSvg {\n      stroke: #ff6f19;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowButtonRight": `Slider_arrowButtonRight__L7eub`,
	"arrowSvg": `Slider_arrowSvg__LGNK3`,
	"arrowButtonLeft": `Slider_arrowButtonLeft__a4Nd-`
};
export default ___CSS_LOADER_EXPORT___;
