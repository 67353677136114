import React from 'react'

const DeviceDetails = ({ details, device }) => {
	return (
		<div className='flex flex-wrap gap-2.5'>
			{details.map((detail, index) => (
				<h5
					key={index}
					className='m-text flex items-center gap-x-2 py-1 px-3 bg-grey/30 rounded-xl'>
					{detail.label}: {detail.value}
				</h5>
			))}
		</div>
	)
}

export default DeviceDetails
