import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import AppRouter from "./components/AppRouter/AppRouter.js";
import LayoutClient from "./components/Layout/Layout.js";
import Loader from "./components/ui/Loader/Loader.js";
const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <LayoutClient>
          <AppRouter />
        </LayoutClient>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
