// src/Game.js
import React, { useState, useEffect } from 'react';
import {randomNumber} from "../../../utils/math";
import './Game.css';
import GearAnimation from "./GearAnimation";
import ErrorAlert from "../../ui/modal/ErrorAlert/ErrorAlert";


const setInitialGrid = (length, gears) => {
    let grid = []
    for (let x = 0; x < length; x++) {
        grid.push(
            Array.from(
                { length: length },
                (_, index) => {return {
                        animateBlust: false,
                        value: gears[randomNumber(0, gears.length - 1)],
                        dropDown: 0
                    }
                }
            )
        )
    }
    return grid;
}

const deserializeIndex = (index) => {
    return Array.from(index.split("-").map((str_number) => Number(str_number)))
}

const transposeMatrix = (matrix) => {
    const transposed = Array.from(
        { length: matrix.length },
        () => Array(matrix.length).fill(0)
    );
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix.length; j++) {
        transposed[j][i] = matrix[i][j];
      }
    }
    return transposed;
}

const GamePage = () => {
  const types = ['⚙️', '🔧', '🔩', '🛠️', '🗜️'];
  const scoreMinValue = 100;
  const [score, setScore] = useState(0)
  const [moves, setMoves] = useState(10)
  const [gridSize, setGridSize] = useState(8)
  const [grid, setGrid] = useState(setInitialGrid(gridSize, types))
  const [gridClearedState, setGridClearedState] = useState(false)
  const [gridResetState, setGridResetState] = useState(false)
  const [activeGear, setActiveGear] = useState(null)
  const [switchGear, setSwitchGear] = useState(null)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [errorAlertShow, setErrorAlertShow] = useState(false)
  const [errorStatus, setErrorStatus] = useState('')
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (isPlaying) {
      findLines(JSON.parse(JSON.stringify(grid)));
    }
  }, [isPlaying]);

  useEffect(() => {
    if (moves <= 0) {
        setAlertMessage(`Вы набрали ${score} очков!`)
        setAlertTitle("Игра закончилась!")
        setErrorStatus('228')
        setErrorAlertShow(true)
    }
  }, [moves]);

  useEffect(() => {
     const execute = () => {
          const myGrid = JSON.parse(JSON.stringify(grid));
          transposeMatrix(myGrid).map((row, rowIndex) => {
              row = row.reverse()
              row.map((gear, colIndex) => {
                  if (!gear.value) return;
                  let dropCount = row.filter(
                      (el, elIndex) => elIndex < colIndex && el.value == null
                  ).length;
                  colIndex = row.length - colIndex - 1;
                  myGrid[colIndex + dropCount][rowIndex].value = gear.value
                  myGrid[colIndex + dropCount][rowIndex].dropDown = dropCount
                  myGrid[colIndex + dropCount][rowIndex].animateBlust = false
                  if (dropCount !== 0) myGrid[colIndex][rowIndex].value = null;
              })
              let count = row.filter(el => !el.value).length;
              for (let i = 1; i <= count; i++) {
                  myGrid[count - i][rowIndex].value = types[randomNumber(0, types.length - 1)]
                  myGrid[count - i][rowIndex].dropDown = count - i + 1
                  myGrid[count - i][rowIndex].animateBlust = false
              }
          })
          setGridResetState(true);
          setGridClearedState(false);
          setGrid(myGrid);
      }
      if (gridResetState) {
          setTimeout(() => {findLines(JSON.parse(JSON.stringify(grid)));}, 1000)
      } else if (gridClearedState) {
          setTimeout(execute, 1000)
      }
  }, [grid])

  const resetActionGears = (ev) => {
      setActiveGear(null);
      setSwitchGear(null);
  }

  const pressGear = (ev) => {
      setActiveGear(ev.target);
  }

  const hoverGear = (ev) => {
      if (!activeGear || switchGear) return;
      setSwitchGear(ev.target);
  }

  const findLines = (myGrid) => {
      setGridResetState(false);
      let [total, changed] = [0, false];

      const findOnReverse = (reverse = false) => {
          let innerGrid = !reverse ? myGrid : transposeMatrix(myGrid);
          innerGrid.map((row, rowIndex) => {
              let [previousGear, count] = [null, 0];
              [...row, 0].map((gear, colIndex) => {
                  if (gear.value !== previousGear) {
                      if (count >= 2 && previousGear !== null) {
                          changed = true;
                         for (let i = colIndex - count - 1; i < colIndex; i++) {
                             if (reverse) [i, rowIndex] = [rowIndex, i];
                             myGrid[rowIndex][i].value = null;
                             myGrid[rowIndex][i].animateBlust = true;
                             if (reverse) [i, rowIndex] = [rowIndex, i];
                         }
                         total += scoreMinValue * (count + 1)
                      }
                      [previousGear, count] = [gear.value, 0];
                  } else {
                      count++;
                  }
              })
          })
      }
      findOnReverse()
      findOnReverse(true)
      setGridClearedState(true)
      if (changed) {
          setScore(prevScore => prevScore + total)
          setGrid(myGrid)
      } else {
          setGridResetState(true)
      }
      return changed;
  }

  useEffect(() => {
      if (!switchGear || moves <= 0 || !isPlaying || !(gridResetState && gridClearedState)) return;
      let [x1, y1] = deserializeIndex(activeGear.getAttribute("data-index"))
      let [x2, y2] = deserializeIndex(switchGear.getAttribute("data-index"))

      let xDirection = x1 < x2 ? 1 : -1;
      let yDirection = y1 < y2 ? 1 : -1;

      let endValue = x1 !== x2 ? xDirection : yDirection;
      let styleClass = x1 !== x2 ? "cell__wrapper_switch_floor" : "cell__wrapper_switch_side";

      const myGrid = JSON.parse(JSON.stringify(grid));

      activeGear.style.setProperty("--start-direction", 0)
      activeGear.style.setProperty("--end-direction", endValue)
      switchGear.style.setProperty("--start-direction", 0)
      switchGear.style.setProperty("--end-direction", -endValue)
      activeGear.classList.add(styleClass)
      switchGear.classList.add(styleClass)

      let prevValue = myGrid[x1][y1].value;
      myGrid[x1][y1].value = myGrid[x2][y2].value
      myGrid[x2][y2].value = prevValue;

      setTimeout(() => {
          activeGear.classList.remove(styleClass)
          switchGear.classList.remove(styleClass)
          if (!findLines(myGrid)) {
              activeGear.style.setProperty("--start-direction", endValue)
              activeGear.style.setProperty("--end-direction", 0)
              switchGear.style.setProperty("--start-direction", -endValue)
              switchGear.style.setProperty("--end-direction", 0)
              activeGear.classList.add(styleClass)
              switchGear.classList.add(styleClass)
          } else {
              setMoves(prevMoves => prevMoves - 1)
          }
          setTimeout(() => {
            activeGear.classList.remove(styleClass)
            switchGear.classList.remove(styleClass)
          }, 500)
      }, 500)

  }, [switchGear])

  document.addEventListener("mouseup", resetActionGears)
  document.addEventListener("touchend", resetActionGears)


  return (
      <div className="game-container">
          <h1>Игра "Три в ряд"</h1>
          <h2>Очки: {score}</h2>
          <h2>Осталось ходов: {moves}</h2>
          {isPlaying && (
              <div className="playground">
                  <div className="grid">
                      <div className="grid__wrapper">
                          {grid.map((row, rowIndex) => (
                              <div key={rowIndex} className="row">
                                  {row.map((cell, colIndex) => (
                                      <div className="cell" key={`${rowIndex}-${colIndex}`}>
                                          <div
                                              className={"cell__wrapper" + (
                                                  gridResetState && !gridClearedState ? ' cell__wrapper_move' : '')
                                              }
                                              data-index={`${rowIndex}-${colIndex}`}
                                              onMouseDown={pressGear}
                                              onMouseOver={hoverGear}
                                              onTouchStart={pressGear}
                                              onTouchMove={hoverGear}
                                              style={{'--drop-down': cell.dropDown}}
                                          >
                                              {cell.value}
                                          </div>
                                          {cell.animateBlust && (
                                              <GearAnimation/>
                                          )}
                                      </div>
                                  ))}
                              </div>
                          ))}
                      </div>
                  </div>
              </div>
          )}
          {!isPlaying && (
              <div className="play-button-container">
                  <button className="play-button" onClick={() => {
                      setIsPlaying(true)
                  }}>
                      ▶️
                  </button>
              </div>
          )}
          <ErrorAlert
              open={errorAlertShow}
              setOpen={setErrorAlertShow}
              title={alertTitle}
              status={errorStatus}
              message={alertMessage}
              isLogin={true}
          />
      </div>
  );
};

export default GamePage;