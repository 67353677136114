import styles from "./Navbar.module.scss";

import { useEffect, useRef, useState, useContext } from "react";
import { Bell } from "lucide-react";
import {check, deleteProfileNotifications} from "../../../http/userAPI";
import useWebSocket, { ReadyState } from 'react-use-websocket'
import {Context} from "../../../index";
import {showNotification} from "../../../utils/notifications";

const NotificationMenu = ({ notifications, setNotifications }) => {
  const bellButtonRef = useRef(null);
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { user } = useContext(Context)
  const websocket_host = process.env.REACT_APP_API_URL.replace('https://', 'wss://').replace(
      'http://',
      'ws://',
  )

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
      websocket_host + 'notifications/connect',
      {
        share: false,
        shouldReconnect: () => true,
      },
  )

  useEffect(() => {
    if (!user.isAuth) {
      check().then()
    }

    if (readyState === ReadyState.CONNECTING) {
        console.log('Connection opened')
    }
    if (readyState === ReadyState.CLOSED) {
        console.log('Connection closed')
    }

	}, [readyState, user])

  useEffect(() => {
      let res = JSON.parse(lastJsonMessage)
      if (res?.data) {
          setNotifications(notifications => {
            const previous = [...notifications];
            if (previous.length > 0) {
              previous.map((obj, position) => {
                if (obj.id === res.data.id) {
                  notifications[position] = res.data;
                }
              })
              return notifications;
            }
            return [res.data]
          })
          showNotification(res.data.text, {body: 'Gear Store', icon: '/images/gear-logo.svg'})
      }
  }, [lastJsonMessage])

  const handleBellClick = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !bellButtonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  const notificationStyles = {
    container: {
      position: "absolute",
      padding: "20px",
      width: "fit-content",
      left: "-100%",
      margin: "auto",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
    },
    notification: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      padding: "15px",
      margin: "10px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
    },
    text: {
      margin: 0,
      flex: 1,
    },
    link: {
      color: "#ff6f19",
    },
    closeButton: {
      backgroundColor: "red",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "5px 10px",
      cursor: "pointer",
    },
  };

  const crossStyles = {
    container: {
      top: "5%",
      right: "5%",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      backgroundColor: "#f44336",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      position: "absolute",
    },
    cross: {
      width: "65%",
      height: "2px",
      borderRadius: "2px",
      backgroundColor: "white",
      position: "absolute",
      transform: "rotate(45deg)",
    },
    cross2: {
      width: "65%",
      height: "2px",
      borderRadius: "2px",
      backgroundColor: "white",
      position: "absolute",
      transform: "rotate(-45deg)",
    },
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [removingId, setRemovingId] = useState(null);


  return (
    <div
      className="relative ml-3"
      style={{
        flexDirection: "row",
        display: "flex",
        padding: "0",
        margin: "0",
      }}
    >
      <div className="relative">
        <button
          className="relative flex"
          ref={bellButtonRef}
          onClick={handleBellClick}
        >
          <div className="button button-user">
            <Bell />
          </div>
        </button>
        {
          notifications.length !== 0 ?
          <div className="absolute top-0 right-0 rounded-full bg-accent w-3 border-2 border-white h-3"></div>
          :
          <></>
        }
      </div>
      {menuOpen && (
        <div
            ref={menuRef}
          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={{ top: "100%" }}
        >
          <div
            style={{ padding: "0", maxHeight: "625px", overflowY: "scroll" }}
          >
          <div className="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
            {notifications.length === 0 ? (
              <p>Уведомлений нет</p>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`transition-all duration-300 ${
                    removingId === notification.id ? "scale-0" : ""
                  }`}
                  style={notificationStyles.notification}
                >
                  <div
                    onClick={async () => {
                      setRemovingId(notification.id);
                      setTimeout(async () => {
                        setNotifications((prev) =>
                          prev.filter((notif) => notif.id !== notification.id)
                        );
                        await deleteProfileNotifications(notification.id);
                        setMenuOpen(false);
                        window.location.href = notification.url;
                      }, 300);
                    }}
                  >
                    <p
                      style={notificationStyles.text}
                      className={styles.notificationText}
                    >
                      {notification.text}
                    </p>
                    <p style={notificationStyles.link}>Перейти</p>
                  </div>
                  <div
                    style={crossStyles.container}
                    onClick={async () => {
                      setRemovingId(notification.id);
                      setTimeout(async () => {
                        setNotifications((prev) =>
                          prev.filter((notif) => notif.id !== notification.id)
                        );
                        await deleteProfileNotifications(notification.id);
                      }, 300);
                    }}
                  >
                    <div style={crossStyles.cross} />
                    <div style={crossStyles.cross2} />
                  </div>
                </div>
              ))
            )}
          </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
