import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { fetchDevises } from '../../../http/deviceAPI'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const RelatedProducts = () => {
	// const [relatedProducts, setRelatedProducts] = useState([
	// 	{ id: 1, name: 'Related Product 1', price: 2000, image: '/images/ajshdbjhb.jpg' },
	// 	{ id: 2, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
	// 	{ id: 3, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
	// 	{ id: 4, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
	// ])
	const defaultImage = "/images/ajshdbjhb.jpg";
	const [related, setRelatedProducts] = useState([])
	const swiperRef = useRef(null);
	useEffect(() => {
		fetchDevises({page: 1, count: 10}).then((data) => {
			setRelatedProducts(data?.products);
		});

	}, []);

	return (
		<div className='mt-12 mb-12'>
			<h2 className='title-l mb-6'>Может вам понравиться</h2>
			<div className="flex flex-col justify-between items-center mb-4" style={{position: 'relative'}}>
				<Swiper
					modules={[Autoplay]}
					spaceBetween={10}
					slidesPerView={4}
					onSwiper={(swiper) => (swiperRef.current = swiper)}
					autoplay={{ delay: 3000, disableOnInteraction: false }}
					style={{width: '100%'}}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						480: {
							slidesPerView: 2,
						},
						640: {
						  slidesPerView: 3,
						},
						1024: {
						  slidesPerView: 4,
						},
						1280: {
						  slidesPerView: 5,
						}
					  }}
					loop>

					{related.map((product, index) => (
						<SwiperSlide key={index}>
							<NavLink key={product.id} to={`/device/${product.id}`} className='group'>
								<div className='bg-grey/30 tr hover:bg-grey/50 p-4 rounded-xl'>
									<img
										src={product.images.length !== 0 ? product.images[0] : defaultImage}
										alt={product.title}
										className='w-full h-40 object-cover mb-4 rounded-lg'
									/>
									<h3 className='text-lg font-medium mb-2'>{product.title}</h3>
									<p className='text-gray-700'>{product.price} руб.</p>
									<button className='mt-6 bg-accent tr px-4 py-2 rounded-xl group-hover:opacity-80'>
										Подробнее
									</button>
								</div>
							</NavLink>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	)
}

export default RelatedProducts
