import React from 'react'
import ChangePasswordPage from '../../components/screens/changePassword/ChangePassword';

const ChangePassword = () => {
	return (
		<div className='container'>
			<ChangePasswordPage />
		</div>
	)
}

export default ChangePassword
