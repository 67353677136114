import { Menu, Transition } from "@headlessui/react";
import { User, Bell, Wrench } from "lucide-react";
import { observer } from "mobx-react-lite";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../..";
import {
  fetchAllCategories,
  fetchCarsBrands,
} from "../../../http/carAndDetailSelectorAPI";
import {
  check,
  handleLogout,
  getProfileNotifications,
} from "../../../http/userAPI";
import {
  CHAT_ROUTE,
  CREATE_AN_AD,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  REGISTRATION_ROUTE,
  USER_PROFILE_ADS,
  SHOP_ROUTE,
} from "../../../utils/consts";
import ProfileUser from "../../modals/ProfileUser";
import Logo from "../logo/Logo";
import styles from "./Navbar.module.scss";
import NotificationMenu from "./NotificationMenu";
import PlaceAnAdSmall from "../../mainPage/placeAnAdSmall/PlaceAnAdSmall";
import BuyAPartSmall from "../../mainPage/buyAPartSmall/BuyAPartSmall";
import CustomNavLink from "../CustomNavLink/CustomNavLink";

const Navbar = observer(() => {
  // const userNavigation = [{ name: 'Ваш профиль', href: USER_PROFILE }]
  const [notifications, setNotifications] = useState([]);
  const { user, device } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCarsBrands().then((data) => device.setTypes(data));
    fetchAllCategories().then((data) => device.setCategories(data));
  }, [device]);

  const pathname = useLocation().pathname;
  const [openProfileModal, setOpenProfileModal] = useState(false);


  useEffect(() => {
    check()
      .then((data) => {
        if (data?.status === 200) {
          user.setIsAuth(true);
          user.setId(data.data?.id);
          getProfileNotifications().then((res) => {
              setNotifications(res?.data ? res?.data : []);
          });
        }
      })
      .catch((error) => {
        user.setIsAuth(false);
      });
    window.scrollTo(0, 0);
  }, [pathname, user]);
 
  return (
    <div className={`${styles.navbar} container`}>
      <Logo />

      {user.isAuth ? (
        <div className="flex flex-row">
          <NotificationMenu
            notifications={notifications}
            setNotifications={setNotifications}
          ></NotificationMenu>

          <Menu
            as="div"
            className="relative ml-3"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <div>
              <Menu.Button className="relative flex">
                <div className="button button-user">
                  <User />
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style={{ top: "100%" }}
              >
                {/* {userNavigation.map(item => (
								<Menu.Item key={item.name}>
									{({ active }) => (
										<a
											href={item.href}
											className={classNames(
												active ? 'bg-gray-100' : '',
												'block px-4 py-2 text-sm text-gray-700',
											)}>
											{item.name}
										</a>
									)}
								</Menu.Item>
							))} */}
                <Menu.Item>
                  <div
                    type="button"
                    onClick={() => {
                      setOpenProfileModal(true);
                    }}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-900 hover:bg-gray-100 w-full text-left"
                  >
                    Мой профиль
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    to={USER_PROFILE_ADS}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-900 hover:bg-gray-100 w-full text-left"
                  >
                    Мои объявления
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    to={CHAT_ROUTE + `/${user.id}`}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-900 hover:bg-gray-100 w-full text-left"
                  >
                    Мои чаты
                  </NavLink>
                </Menu.Item>
                {
                  pathname !== MAIN_ROUTE &&
                  <Menu.Item>
                  <NavLink
                    to={MAIN_ROUTE}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-900 hover:bg-gray-100 w-full text-left"
                  >
                    На главную
                  </NavLink>
                </Menu.Item>
                }
                <Menu.Item>
                  <div
                    type="button"
                    onClick={() => {
                      user.setIsAuth(false);
                      handleLogout();
                    }}
                    className="block px-4 py-2 cursor-pointer text-sm text-gray-900 hover:bg-gray-100 w-full text-left"
                  >
                    Выйти
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      ) 
      : 
      pathname === LOGIN_ROUTE || pathname === REGISTRATION_ROUTE
      ?
      //<CustomNavLink isNavbar={true} linkTo={MAIN_ROUTE} text={'На главную'} />
      <NavLink className="button h-12" to={MAIN_ROUTE}>
      На главную
      </NavLink>
      :
      <NavLink className="button h-12" to={LOGIN_ROUTE}>
      Войти
    </NavLink>
      }

      <ProfileUser show={openProfileModal} onHide={setOpenProfileModal} />
    </div>
  );



});

export default Navbar;