import { $authHost, $host } from ".";

export const fetchCarsBrands = async (brandSearch = "") => {
  try {
    const { data } = await $host.get("/api/cars/brands", {
      params: { search: brandSearch, sort_by: "score" },
    });
    const modifiedData = data
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((category) => {
        return {
          ...category,
          name: category.name,
        };
      });

    return modifiedData;
  } catch (e) {
    console.error(e);
  }
};

export const createCarsBrands = async (nameBrand) => {
  try {
    const { data } = await $authHost.post("/api/cars/brands", nameBrand);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchModels = async (selectedCategoryId, typeSearch = "") => {
  try {
    const { data } = await $host.get(
      `/api/cars/brands/${selectedCategoryId}/models`,
      {
        params: { search: typeSearch, sort_by: "score" },
      }
    );
    const modifiedData = data
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((models) => {
        return {
          ...models,
          name: models.name,
        };
      });

    return modifiedData;
  } catch (e) {
    console.error(e);
  }
};

export const fetchGenerations = async (
  selectedBrandId,
  selectedModelId,
  genSearch = ""
) => {
  try {
    const { data } = await $host.get(
      `/api/cars/brands/${selectedBrandId}/models/${selectedModelId}/gens`,
      {
        params: { search: genSearch, sort_by: "score" },
      }
    );

    const modifiedData = data
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((generations) => {
        return {
          ...generations,
          name: generations.name,
        };
      });

    return modifiedData;
  } catch (e) {
    console.error(e);
  }
};

export const fetchAllCategories = async (categorySearch = "") => {
  try {
    const { data } = await $host.get("/api/details/categories", {
      params: { search: categorySearch, sort_by: "score" },
    });

    const modifiedData = data
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((category) => {
        return {
          ...category,
          name: category.name,
        };
      });

    return modifiedData;
  } catch (e) {
    console.error(e);
  }
};

export const fetchCategoriesTypes = async (
  categoriesType,
  categorySearch = ""
) => {
  try {
    const { data } = await $host.get(
      `/api/details/categories/${categoriesType}/types`,
      {
        params: { search: categorySearch, sort_by: "score" },
      }
    );

    const modifiedData = data
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((category) => {
        return {
          ...category,
          name: category.name,
        };
      });

    return modifiedData;
  } catch (e) {
    console.error(e);
  }
};
