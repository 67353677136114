import React from 'react'

const Mailing = () => {
	return (
		<div className='container'>
			<h1 className='text-xl md:text-2xl'>
				Согласие на получение информационной и рекламной рассылки
			</h1>
			<p className='mt-10'>
				Настоящим свободно, своей волей, в своем интересе и подтверждая свою дееспособность,
				выражаю свое согласие на получение от{' '}
				<strong>Общества с ограниченной ответственностью «ГИР СТОР»</strong>,
				зарегистрированного по адресу:{' '}
				<em>
					601443, Владимирская область, Вязниковский р-н, г. Вязники, ул. Ленина, стр. 46
				</em>
				сообщений и любой иной информационной и рекламной рассылки посредством:
			</p>

			<ul className='mt-5'>
				<li>СМС;</li>
				<li>push-уведомлений;</li>
				<li>приложений;</li>
				<li>мессенджеров;</li>
				<li>телефонных звонков;</li>
				<li>электронной почты;</li>
				<li>
					иным образом на предоставленные мною в адрес номер телефона и/или адрес
					электронной почты.
				</li>
			</ul>

			<p className='mt-3'>Я подтверждаю, что уведомлен о следующем:</p>

			<ul className='mt-5'>
				<li>
					В случае нежелания получать рассылку на указанных в настоящем согласии условиях
					мне следует снять отметку в поле{' '}
					<strong>
						«Согласен получать сообщения рекламного и информационного характера»
					</strong>
					&nbsp; путем нажатия на нее;
				</li>
				<li>
					Я могу в любое время изменить настройки рекламных рассылок либо полностью
					отказаться от их получения посредством функционала сайта.
				</li>
			</ul>

			<p className='mt-5'>
				Я также подтверждаю, что предоставленные мною номер телефона и/или адрес электронной
				почты принадлежат мне, а направляемая на них информация предназначена только для
				меня и не будет получаться третьими лицами, не давшими ООО «ГИР СТОР»
				предварительного согласия на ее получение.
			</p>
		</div>
	)
}

export default Mailing
