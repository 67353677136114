import React from "react";
import styles from './AddPhoto.module.scss'

const PhotoItems = ({photos, setPhotos, setImage}) => {

    const handleRemovePhoto = (index) => {
        setPhotos(photos.filter((_, i) => i !== index));
        setImage(photos.filter((_, i) => i !== index));
      };

  return (
    <>
    {photos.map((photo, index) => (
    <div key={index} className={styles.photoContainer}>
      <img src={URL.createObjectURL(photo)} alt={`Фото ${index + 1}`} />
      <button
        type="button"
        className={styles.crossButton}
        onClick={() => handleRemovePhoto(index)}
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={styles.crossSvg}
            d="M1.46484 8.53484L8.53684 1.46484M1.46484 1.46484L8.53684 8.53484"
            stroke="black"
            strokeWidth="1"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
          ))}
    </>
  );
};

export default PhotoItems;
