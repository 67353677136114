import React, { useEffect, useState } from "react";
import { useScreenSize } from "../../../utils/hooks/hooks";
import Fancybox from '../Fancybox/Fancybox';
import SliderButton from "./SliderButton";

const Slider = ({ deviceItem }) => {
  const defaultImage = "/images/ajshdbjhb.jpg";
  const [images, setImages] = useState([defaultImage]);
  useEffect(() => {
    if (deviceItem?.images != null) {
      setImages(deviceItem?.images);
    }
  }, [deviceItem]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobileScreen = useScreenSize(768);
  const [pointTouchStart, setPointTouchStart] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="mx-auto xl:h-80" style={{ height: "fit-content" }}>
      <div
        className="relative w-full h-80 overflow-hidden rounded-2xl"
        onTouchEnd={(e) => {
          if (e.changedTouches[0].clientX - pointTouchStart < -30) {
            handleNext();
          }
          if (e.changedTouches[0].clientX - pointTouchStart > 30) {
            handlePrev();
          }
        }}
        onTouchStart={(e) => {
          setPointTouchStart(e.changedTouches[0].clientX);
        }}
      >
        <div
          className="flex transition-transform duration-500"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            height: "100%",
          }}
        >
          {images.length === 0 ? (
            <Fancybox options={{ infinite: false }}>
              <a
                data-fancybox="gallery"
                href={defaultImage}
                className="w-full h-full flex-shrink-0 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${defaultImage})`,
                  height: "100%",
                  display: "block",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></a>
            </Fancybox>
          ) : (
            <></>
          )}
          {images.map((image, index) => (
            <Fancybox
            >
                        <a
            key={index}
            data-fancybox="gallery"
            href={image}
            className="w-full h-full"
            style={{
              backgroundImage: `url(${image})`,
              height: "100%",
              display: "block",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            >
            </a>
            </Fancybox>
          ))}
        </div>

        {images.length > 1 ? (
          <>
            <SliderButton func={handlePrev} direction={-1}></SliderButton>
            <SliderButton func={handleNext} direction={1}></SliderButton>

            <div className="absolute bottom-4 left-4 bg-gray-800 text-white px-3 py-1 rounded text-sm">
              {currentIndex + 1} / {images.length}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {!isMobileScreen && 
         images.length > 2 ? 
        <div className="flex justify-center items-center mt-4 gap-2">
          {images.map((image, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-16 h-16 border-2 rounded overflow-hidden transition-transform duration-300 ${
                currentIndex === index
                  ? "border-accent scale-105"
                  : "border-gray-300 hover:scale-105"
              }`}
            >
              <img
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </button>
          ))}
        </div>
        : <></>

      }
    </div>
  );
};

export default Slider;
