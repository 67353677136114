import { CarFront, ChevronRight, Wrench } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { Context } from '../../..'
import { CREATE_AN_AD, REGISTRATION_ROUTE, SHOP_ROUTE } from '../../../utils/consts'
import BuyAPart from '../../mainPage/buyAPart/BuyAPart'
import PlaceAnAd from '../../mainPage/placeAnAd/PlaceAnAd'
import styles from './Main.module.scss'
import AccessAlert from '../../ui/modal/AccessAlert/AccessAlert'
import { useScreenSize } from '../../../utils/hooks/hooks'

const MainPage = observer(() => {
	const { user } = useContext(Context)
	const [searchParams, setSearchParams] = useSearchParams();
	const [accessAlertShow, setAccessAlertShow] = useState(false);
	const [alertTitle, setAlertTitle] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
  	const confirm = searchParams.get('confirm');

	useEffect(() => {
		if (confirm) {
			setAccessAlertShow(true)
			setAlertTitle('Вы успешно активировали аккаунт.')
			setSearchParams({});
		}
	}, [confirm])

	const isMobileScreen = useScreenSize(440)
	return (
		<div className='pb-10'>
			<div className={`${styles.wrap} flex justify-between`}>
				<div className={styles.wrap__left}>
					<h1>
						С&nbsp;вероятностью
						<br />
						96% продаем
						<br />
						деталь
					</h1>
					<div className={`${styles.wrap__left__inline__wrap}`}>
						{!user.isAuth ? (
							<NavLink to={REGISTRATION_ROUTE}>
								<div className={`${styles.wrap__left__inline}`}>
									<button type='button' className={`button button-black ${styles.button}`}>
										Зарегистрироваться
									</button>
									<span className={styles.circle}>
										<span className={`flex justify-center items-center ${styles.circle__in}`}>
											<ChevronRight className={styles.arrow} />
										</span>
									</span>
								</div>
							</NavLink>
						) : (
							''
						)}
					</div>
				</div>
				<div className={`${styles.wrap__right} flex justify-center`}>
					<div
						className={styles.wrap__right__img}
						style={{ backgroundImage: 'url(/images/sbdhfhjsd.jpg)' }}></div>
					{/* style={{ backgroundImage: 'url(/images/bsjdhfb.png)' }}></div> */}
				</div>
			</div>

			<div className='xl:flex-row flex-col gap-y-10 flex justify-between mt-8 lg:mt-12 items-center xl:items-end'>
				<div className='lg:flex-row flex gap-6 flex-col w-full justify-center lg:justify-start xl:max-w-fit'>
					<PlaceAnAd
						type=''
						icon={<CarFront />}
						headerTitle={<div className={styles.underTitle}>500К моделей</div>}
						underTitle={
							<h5 className='title'>
								Разместить
								<br />
								объявление
							</h5>
						}
						link={CREATE_AN_AD}
						status={!user.isAuth ? 'Unauthorized' : 'Authorized'}
					/>

					<BuyAPart
						icon={<Wrench />}
						headerTitle='Перейти'
						underTitle={<h5 className='title'>Купить деталь</h5>}
						pieces='200К шт'
						tires='70 шин'
						link={SHOP_ROUTE}
					/>
				</div>

				<div className={`${styles.under__photo}`}>
					<div className={`flex ${isMobileScreen ? 'justify-center gap-8' : 'justify-between'} w-full px-1 flex-row flex-wrap gap-6`}>
						<div className='under__photo__text-item flex flex-col items-center'>
							<div className={`${isMobileScreen ? 'text-2xl' : 'title'}`}>100К+</div>
							Объявлений
						</div>

						<div className='under__photo__text-item flex flex-col items-center'>
							<div className={`${isMobileScreen ? 'text-2xl' : 'title'}`}>70К+</div>
							Доставок
						</div>

						<div className='under__photo__text-item flex flex-col items-center'>
							<div className={`${isMobileScreen ? 'text-2xl' : 'title'}`}>50К+</div>
							Продаж
						</div>
					</div>
					<div
						className={`${styles.under__photo__deliv} flex flex-col xs:flex-row lg:flex-row mt-[50px] bg-accent br-9 items-center`}>
						<h4 className='title bg-grey p-8 pr-4 color-dark'>Сотрудничаем со</h4>
						<div className='flex xs:flex-row flex-col items-center gap-[20px] xs:p-4 p-8 justify-between'>
							<svg
								width='82'
								height='23'
								viewBox='0 0 82 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<g clipPath='url(#clip0_1_167)'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M55.3667 22.8614H61.1154L63.5855 15.6702L66.1531 13.466L68.176 19.9693C68.8018 21.9795 69.4457 22.8614 70.8505 22.8614H75.2516L70.7113 10.1L81.6308 0H74.5788L67.9502 7.19136C67.1783 8.02849 66.3972 8.85243 65.6138 9.83663H65.5472L68.8569 0H63.108L55.3667 22.8614Z'
										fill='black'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M54.877 0.260531C56.6964 0.260826 58.4608 0.261112 59.9387 0.261241L58.7413 3.96069C58.3651 5.12301 57.4098 5.6791 55.3045 5.6791C51.6489 5.6791 46.2399 5.67846 42.584 5.67782L43.7814 1.97837C44.1576 0.815405 45.1127 0.259644 47.2181 0.259644C49.3959 0.259644 52.196 0.260097 54.877 0.260531ZM44.2658 8.75335C47.9212 8.75335 53.3304 8.75402 56.9863 8.75497L55.7889 12.4543C55.4127 13.6169 54.4576 14.1726 52.352 14.1726C48.6965 14.1726 43.2874 14.172 39.6316 14.1711L40.829 10.4721C41.2052 9.30927 42.1604 8.75335 44.2658 8.75335ZM53.8889 17.2486C50.2331 17.248 44.824 17.2473 41.1685 17.2473C39.0627 17.2473 38.1078 17.8034 37.7316 18.9654L36.5342 22.6654C40.1901 22.6657 45.5991 22.6668 49.2548 22.6668C51.3601 22.6668 52.3154 22.1105 52.6915 20.9479L53.8889 17.2486Z'
										fill='black'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M28.9485 5.50632L32.0753 5.50763C34.7394 5.50828 34.1313 8.80081 32.783 12.0098C31.595 14.8382 29.4894 17.334 26.9774 17.3337L21.7648 17.3331C19.6857 17.3331 18.7271 17.9004 18.3227 19.0859L17.0356 22.8607L20.8581 22.8614L24.5944 22.8306C27.908 22.8036 30.6208 22.5666 33.793 19.7454C37.1455 16.7651 41.0543 9.02283 40.3846 4.54473C39.86 1.03558 37.9545 0.00162981 33.3404 0.00130385L24.932 0L20.0363 14.179L23.147 14.183C24.9982 14.185 25.9274 14.208 26.9122 11.5857L28.9485 5.50632Z'
										fill='black'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M14.343 5.52605L12.0286 5.5267C7.39217 5.52898 3.41916 17.3356 8.90117 17.3337L12.4235 17.3331C14.455 17.3331 15.9631 18.079 15.2954 20.0904L14.3752 22.8607L10.6394 22.8614L7.60073 22.8306C3.71337 22.7914 1.20484 20.8645 0.301606 17.9387C-0.675828 14.7725 0.704137 8.16865 4.70931 3.93828C7.03325 1.48411 10.2524 0.00162981 14.3797 0.00130385L22.0049 0L20.8129 3.46009C20.0428 5.69571 18.4694 5.52898 17.5715 5.52865L14.343 5.52605Z'
										fill='black'
									/>
								</g>
								<defs>
									<clipPath id='clip0_1_167'>
										<rect width='82' height='23' fill='white' />
									</clipPath>
								</defs>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}/>
		</div>
	)
})

export default MainPage