import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { Context } from '../..'
import DeviceDetails from '../../components/ui/DeviceItem/DeviceDetails'
import FlexWrapper from '../../components/ui/DeviceItem/FlexWrapper'
import ErrorAlert from '../../components/ui/modal/ErrorAlert/ErrorAlert'
import RelatedProducts from '../../components/ui/RelatedProducts/RelatedProducts'
import RenderBreadcrumbs from '../../components/ui/RenderBreadcrumbs/RenderBreadcrumbs'
import Slider from '../../components/ui/Slider/Slider'
import { fetchOneProduct } from '../../http/deviceAPI'
import { pinChatMessage } from '../../http/userAPI'
import WriteComplain from '../../components/modals/WriteComplain'

const DevicePage = observer(() => {
	const { id } = useParams()
	const [deviceItem, setDeviceItem] = useState(null)
	const { device, user } = useContext(Context)
	const conditionIndex = device.conditions.findIndex(item => deviceItem?.condition === item.value)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
	const [errorFunc, setErrorFunc] = useState(null)
	const [complainShow, setComplainShow] = useState(false);


	const handleClick = e => {
		if (!user.isAuth) {
			setErrorStatus('')
			setAlertTitle('Вы сможете связаться с продавцом только после регистрации')
			setErrorAlertShow(true)
			e.preventDefault()
		}
	}

	const details = [
		{
			label: 'Состояние детали',
			value:
				conditionIndex !== -1
					? device.conditions[conditionIndex].name
					: device.conditions[0].name,
		},
		{ label: 'Город', value: deviceItem?.address },
		{ label: 'Бренд авто', value: deviceItem?.car_gen?.car_model?.car_brand?.name },
		{ label: 'Модель авто', value: deviceItem?.car_gen?.car_model?.name },
		{ label: 'Поколение авто', value: deviceItem?.car_gen?.name },
		{
			label: 'Категория детали',
			value: deviceItem?.detail_type?.detail_category?.name,
		},
		{ label: 'Тип детали', value: deviceItem?.detail_type?.name },
	]

	useEffect(() => {
		if (id) {
			fetchOneProduct(id).then(data => {
				setDeviceItem(data)
				console.log('data', data);
			}).catch(err => {
				setErrorStatus('Not founf')
				setAlertTitle('Продукт не найден')
				setErrorAlertShow(true)
				setErrorFunc(() => () => {window.location = "/shop"})
			})
		}
	}, [id])
	const defaultImage = "/images/ajshdbjhb.jpg";

	const handleContact = (e) => {
		if (!user.isAuth) {
			setErrorStatus('')
			setAlertTitle('Вы сможете отправлять сообщения только после регистрации')
			setErrorAlertShow(true)
			e.preventDefault()
		} else {
			pinChatMessage(
				deviceItem?.profile?.id, 
				deviceItem ? (deviceItem?.images?.length !== 0 ? deviceItem?.images[0] : defaultImage) : defaultImage,
				details[details.length - 1].value,
				deviceItem?.price,
				deviceItem?.id
			)
			//navigate(`/room/${deviceItem?.profile?.id}`)
		}
	}

	return (
		<div className='container'>
			<div className=''> {<RenderBreadcrumbs details={details} />}</div>
			<div className='flex flex-col gap-6 xl:flex-row mt-6'>
				<div className='xl:w-1/2'>
					<Slider deviceItem={deviceItem}></Slider>
				</div>

				<div className='xl:w-1/2 flex flex-col gap-6'>
					<FlexWrapper>
						<h1 className='title-l'>{`${
							details[details.length - 1].value
								? `${details[details.length - 1].value} ${details[2].value} ${
										details[3].value
								  } ${details[4].value}`
								: ''
						}`}</h1>

						<div className='flex justify-between w-full flex-wrap gap-3'>
							<h3 className='flex items-center gap-x-2 text-xl'>
								Цена: <span>{deviceItem?.price} руб.</span>
							</h3>
							<div className='flex items-center gap-3'>
								Автор: {deviceItem?.profile?.name}
							</div>
						</div>
					</FlexWrapper>
					<div className='flex flex-col gap-y-3'>
						<FlexWrapper>
							<h3 className='m-text flex items-center gap-4 flex-wrap'>
								<NavLink
									className={'flex items-center'}
									to={`/room/${deviceItem?.profile?.id}`}
									onClick={handleContact}>
									<span className='bg-accent py-1 px-3 block rounded-tl-xl rounded-xl'>
										Связаться с продавцом
									</span>
								</NavLink>
								<button 
									className='bg-[#424242] hover:bg-gray-600 py-1 text-white px-3 block rounded-tl-xl rounded-xl'
									onClick={()=> {setComplainShow(true)}}
								>
									Отправить жалобу
								</button>
							</h3>
							<h3 className='m-text flex items-center gap-x-2'>
								<a
									onClick={handleClick}
									className='flex items-center gap-x-2'
									href={`tel: ${deviceItem?.profile?.phone}`}>
									{deviceItem?.profile?.phone}
								</a>
							</h3>
						</FlexWrapper>
						<div className='flex mt-6 flex-wrap gap-2.5'>
							<DeviceDetails details={details} deviceItem={deviceItem} />
						</div>
					</div>
				</div>
			</div>
			<div className='mt-6'>
				<h3 className='text-xl flex items-center gap-x-2'>Описание</h3>
				<div className='m-text mt-3'>{deviceItem?.description}</div>
			</div>
			<RelatedProducts />
			
			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				cancelFunc={errorFunc}
			/>

			<WriteComplain
				open={complainShow}
				setOpen={setComplainShow}
				alertTitle="Написать жалобу на объявление"
				submitTitle="Отправить жалобу"
				productId={deviceItem?.id}
			/>
		</div>
	)
})

export default DevicePage
