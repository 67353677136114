import React from 'react';
import './GearAnimation.css'; // Import the CSS file for styles

const GearAnimation = () => {
    const gears = Array.from({ length: 10 }, (_, index) => index); // Create an array of gears

    return (
        <div className="gear-container">
            {gears.map((gear) => (
                <div key={gear} className={`gear gear-${gear}`}>⚙️</div>
            ))}
        </div>
    );
};

export default GearAnimation;