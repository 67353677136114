// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__60N1p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

@keyframes Loader_rotation__xVpqV {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.Loader_spinner__dXuIs {
	display: flex;
	width: 48px;
	height: 48px;
	border: 5px solid black;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: Loader_rotation__xVpqV 1s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,WAAW;CACX,YAAY;AACb;;AAEA;CACC;EACC,uBAAuB;CACxB;;CAEA;EACC,yBAAyB;CAC1B;AACD;;AAEA;CACC,aAAa;CACb,WAAW;CACX,YAAY;CACZ,uBAAuB;CACvB,gCAAgC;CAChC,kBAAkB;CAClB,qBAAqB;CACrB,sBAAsB;CACtB,oDAAsC;AACvC","sourcesContent":[".loader {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 100%;\n\theight: 100%;\n}\n\n@keyframes rotation {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.spinner {\n\tdisplay: flex;\n\twidth: 48px;\n\theight: 48px;\n\tborder: 5px solid black;\n\tborder-bottom-color: transparent;\n\tborder-radius: 50%;\n\tdisplay: inline-block;\n\tbox-sizing: border-box;\n\tanimation: rotation 1s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__60N1p`,
	"spinner": `Loader_spinner__dXuIs`,
	"rotation": `Loader_rotation__xVpqV`
};
export default ___CSS_LOADER_EXPORT___;
