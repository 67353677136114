import { ArrowUpRight } from "lucide-react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ErrorAlert from "../../ui/modal/ErrorAlert/ErrorAlert";
import styles from "./PlaceAnAdSmall.module.scss";

const PlaceAnAdSmall = ({
  type,
  status,
  icon,
  headerTitle,
  underTitle,
  link = false,
  bgColor = false,
}) => {
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const handleClick = (e) => {
    if (status === "Unauthorized") {
      setErrorStatus("");
      setAlertTitle("Вы сможете создать объявление только после регистрации");
      setErrorAlertShow(true);
      e.preventDefault();
    }
  };

  return (
    <>
      <NavLink
        className={`w-full ${styles.wrap__linker}`}
        to={link}
        onClick={handleClick}
      >
        <div
          className={`${styles.wrap} ${
            bgColor ? styles.grey : styles.accent
          } w-full`}
        >
          <div className={`${styles.wrap__down} flex`}>
            {underTitle}
            <div className={styles.arrow}>
              {!bgColor ? <ArrowUpRight /> : ""}
            </div>
          </div>
        </div>
      </NavLink>
      <ErrorAlert
        open={errorAlertShow}
        setOpen={setErrorAlertShow}
        title={alertTitle}
        status={errorStatus}
      />
    </>
  );
};

export default PlaceAnAdSmall;
