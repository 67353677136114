import { makeAutoObservable } from 'mobx'

export default class LocationStore {
	constructor() {
		this._locations = []

		this._keyCounter = 0

		makeAutoObservable(this)
	}

	setLocations(locations) {
		this._locations = [...locations]
	}

	plusKeyCounter() {
		this._keyCounter += 1
	}

	get locations() {
		return this._locations
	}

	get keyCounter() {
		return this._keyCounter
	}
}