import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { fetchDevises } from "../../../http/deviceAPI";
import { fetchRegions } from "../../../http/locationAPI";
import { useScreenSize } from "../../../utils/hooks/hooks";
import DeviceItem from "../DeviceItem/DeviceItem";
import FilterSortPanel from "./FilterSortPanel";
import Pagination from "./PaginationComponent";
import { resetSearchProducts } from "./SearchUtils";
import { check } from "../../../http/userAPI";
import {Context} from "../../../index";

const DeviceList = observer(() => {
    const { search, device, location } = useContext(Context)
    const [cityRepresentation, setCityRepresentation] = useState("");
    const [isShowArrowTop, setIsShowArrowTop] = useState(false);
    const isMobileScreen = useScreenSize(411);
    const isTabletScreen = useScreenSize(1024);
    const isLaptopScreen = useScreenSize(1280);
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    useEffect(() => {
        fetchDevises({ page: search.page }).then((data) => {
            device.setDevice(data);
        });
        resetSearchProducts(search, device).then();
    }, [device, search]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            fetchRegions({ name: cityRepresentation }).then((data) => {
                location.setLocations(data);
            });
        }, 500)
        location.setLocations([{id: null, name: "", region: {id: null, name: "Загрузка..."}}])
        return () => clearTimeout(timeout)
    }, [cityRepresentation, location]);

    return (
        <div>
            <div
                className={`${
                    isLaptopScreen ? "gap-10" : "gap-0"
                } "mx-auto pb-12 flex flex-col lg:flex-row justify-between group relative w-full"`}
            >
                <button
                    className={`flex flex-row items-center gap-2 mb-5 justify-center ${
                        isTabletScreen
                            ? isFilterVisible
                                ? "hidden"
                                : ""
                            : "hidden"
                    }`}
                    onClick={() => {
                        setIsFilterVisible(!isFilterVisible);
                    }}
                >
                    <span className="flex text-2xl">Фильтры</span>
                    <img
                        className="flex mt-1"
                        src="/images/filter.svg"
                        alt="filter-icon"
                    />
                </button>
                <div
                    className={` ${
                        isTabletScreen ? (isFilterVisible ? "" : "hidden") : ""
                    } xl:border-r border-gray-900/10 pb-6 xl:pb-0 xl:mr-6 relative xl:pr-6`}
                >
                    <div className="sticky xl:w-80 top-6 xl:left-0">
                        <FilterSortPanel
                            isTabletScreen={isTabletScreen}
                            isFilterVisible={isFilterVisible}
                            setIsFilterVisible={setIsFilterVisible}
                            cityRepresentation={cityRepresentation}
                            setCityRepresentation={setCityRepresentation}
                            locations={location.locations}
                        />
                    </div>
                </div>
                <div
                    className={`w-full ${
                        isTabletScreen ? (isFilterVisible ? "hidden" : "") : ""
                    }`}
                >
                    <div className="flex flex-col border-gray-900\/10 pb-6 gap-3 w-full">
                        {device.devices?.products?.length !== 0 ? (
                            device.devices?.products?.map((device) => (
                                <div key={device.id} className="w-full">
                                    <DeviceItem
                                        key={device.id}
                                        device={device}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="text-center mt-8 text-2xl text-gray-800" style={{height: '40vh'}}>
                                Детали не найдены
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row align-center justify-center gap-10">
                        {device.devices?.products?.length !== 0 ? (
                            <>
                                <Pagination
                                    device={device}
                                    search={search}
                                    isMobileScreen={isMobileScreen}
                                    setIsShowArrowTop={setIsShowArrowTop}
                                />
                                <button
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: "smooth",
                                        });
                                    }}
                                    className="flex items-center justify-center bg-accent w-11 h-11 rounded-full"
                                    style={{
                                        position: "fixed",
                                        bottom: `${
                                            isShowArrowTop ? "20px" : "-50px"
                                        }`,
                                        right: "30px",
                                        transition: "bottom 0.3s ease-in-out",
                                        opacity: "0.75",
                                    }}
                                >
                                    <img
                                        className="pt-1"
                                        src="/images/direction-top.svg"
                                        alt="arrow"
                                    />
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DeviceList;
