import cn from 'clsx'
import React, {forwardRef, useState, useEffect, useRef} from 'react'
import styles from './Field.module.scss'
import { onPhoneInput, onPhoneKeyDown, onPhonePaste } from './PhoneInputUtils'
import { Eye, EyeOff } from 'lucide-react'

const Field = forwardRef(
	({ error, style, Icon, className, type, value, full = '', isLogin, clearField, items = [], is_confirmed = false, func = (...args) => {}, ...rest }) => {
		const [passwordLength, setPasswordLength] = useState(value?.length)
		const [isDropdownVisible, setIsDropdownVisible] = useState(false);
		const [showPassword, setShowPassword] = useState(false)
		const cityInput = useRef(null);
		const cityList = useRef(null);

		const handleClickOutside = (event) => {
			if (
			  	cityList.current && !cityList.current.contains(event.target) &&
			  	cityInput.current && !cityInput.current.contains(event.target)
			) {
			  	setIsDropdownVisible(false)
			}
		};

		useEffect(() => {
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
			  document.removeEventListener('mousedown', handleClickOutside);
			};
		}, []);

		const handlePasswordInput = value => {
			setPasswordLength(value?.length)
		}

		const handleInput = e => {
			switch (type) {
				case 'tel':
					onPhoneInput(e)
					break
				case 'password':
					handlePasswordInput(e.target?.value)
					break
				default:
					break
			}
		}

		const handleKeyDown = e => {
			if (type === 'tel') {
				onPhoneKeyDown(e)
			}
		}

		const handlePaste = e => {
			if (type === 'tel') {
				onPhonePaste(e)
			}
		}

		return (
			<label className={cn(styles.field, className)} style={{...style, position: "relative", flexDirection: type === 'password' ? 'row-reverse' : 'row', justifyContent: 'left' }}>
				{Icon && (
					<div className={styles.icon}
						style={{marginLeft: type === 'password' ? 'auto' : '5px', marginRight: type === 'password' ? '0' : '19px'}}
					>
						{
							type !== 'password' 
							?
							<Icon /> 
							:
							<>
								<Eye 
									className={`${!showPassword ? 'hidden' : ''} cursor-pointer`}
									onClick={() => {setShowPassword(false)}}
								/>
								<EyeOff 
									className={`${showPassword ? 'hidden' : ''} cursor-pointer`}
									onClick={() => {setShowPassword(true)}}
								/>
							</>
						}
					</div>
				)}
				<input
					type={type !== 'password' ? type : (showPassword === true ? 'text' : 'password')}
					className={`${full}`}
					ref={cityInput}
					value={value}
					{...rest}
					onKeyDown={handleKeyDown}
					onInput={handleInput}
					onPaste={handlePaste}
					onFocus={() => {if (type === 'city') {setIsDropdownVisible(true)}}}
				/>
				{
					(type === 'city' && !!clearField) ?
						(value &&
						<svg
							onClick={clearField}
							className="absolute cursor-pointer hover:bg-gray-300 rounded-lg"
							style={{ top: "14px", right: '11px' }}
							width="23px"
							height="23px"
							viewBox="0 0 24.00 24.00"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0" />
							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<g id="crossCleanInput">
								{" "}
								<path
									d="M16 8L8 16M8.00001 8L16 16"
									stroke="#000000"
									stroke-width="1.7"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>{" "}
							</g>
						</svg>)
					:
					<></>

				}
				{
					type === 'tel' && (
						is_confirmed ? (
							<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="green">
									<path d="M9 19l-7-7 1.41-1.41L9 16.17l11.59-11.59L22 6l-13 13z"/>
								</svg>
							</span>
						) : (
							<>{
								/* 						
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
									 stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-red-600">
									<path stroke-linecap="round" stroke-linejoin="round"
									  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
									</svg> 
								*/
							}</>

						)
					)
				}
				{
					type === 'city' && isDropdownVisible && (
						<ul style={{
						  position: 'absolute',
						  top: '100%',
						  left: 0,
						  right: 0,
						  listStyleType: 'none',
						  padding: 0,
						  margin: '12px 0 0 0',
						  zIndex: 1000,
						  borderRadius: '12px',
						  overflow: 'hidden',
						  overflowY: 'scroll',
						  maxHeight: "364px"
						}} ref={cityList}>
						  {items.map((item, index) => (
							<li
							  key={index}
							  onMouseDown={() => {
								func(item.id, item.region.name + ", " + item.name);
								setIsDropdownVisible(false)
							  }}
							  style={{
								cursor: 'pointer',
								backgroundColor: '#eaeaea',
								padding: (index === 0 && items.length !== 1 ) ? '16px 16px 8px 16px' : (index === items.length - 1 && items.length !== 1) ? '8px 16px 15px 16px' : '8px 16px 8px 16px',
							  }}
							  onMouseEnter={(e) => {
								e.currentTarget.style.backgroundColor = '#f9fafb'
								e.currentTarget.style.color = '#ff6f19'
							  } }
							  onMouseLeave={(e) => {
								e.currentTarget.style.backgroundColor = '#eaeaea'
								e.currentTarget.style.color = 'inherit'
							  }}
							>
								{item.region.name}, {item.name}
							</li>
						  ))}
						</ul>
					)
				}
				{error && (
					<div
						className={isLogin ? 'none' : styles.error}
						style={{
							color: type === 'password' && passwordLength >= 8 ? 'green' : '#DC2626',
							marginLeft: type === 'password' ? '0' : 'auto',
							marginRight: type === 'password' ? '10px' : '0',
						}}>
						{error.message}
						{type === 'password' && <div style={{width: '40px'}}>{passwordLength + ' / 8'}</div>}
					</div>
				)}
			</label>
		)
	},
)

export default Field
