import { useState } from "react";
import { VALIDATE_STYLE } from "../../../utils/consts";
import { AtSign, Eye } from "lucide-react";
import Field from "../../ui/Field/Field";
import { useParams, NavLink } from "react-router-dom";
import ErrorAlert from "../../ui/modal/ErrorAlert/ErrorAlert";
import AccessAlert from "../../ui/modal/AccessAlert/AccessAlert";
import { changePassword, sendChangePasswordToEmail } from "../../../http/userAPI";

const ChangePasswordPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [isFieldPreparing, setIsFieldPreparing] = useState(true);

    const [errorAlertShow, setErrorAlertShow] = useState(false);
    const [accessAlertShow, setAccessAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [errorStatus, setErrorStatus] = useState("");

    const { jwt_token } = useParams();
	const isEnterEmail = !Boolean(jwt_token)

    const onVerifyEmail = async (e) => {
        e.preventDefault();
        setIsFieldPreparing(false);
        if (email) {
            try {
                const response = await sendChangePasswordToEmail(email)
                setAlertTitle("Мы отправили письмо вам на почту, также проверьте папку спам");
                setAlertMessage(
                    "Перейдите на вашу почту, нажмите на ссылку в письме для смены пароля"
                );
                setAccessAlertShow(true);
            } catch (error) {
                console.error(error);
                setAlertMessage(error.response);
                setErrorStatus(error.response?.status);
                setAlertTitle(
                    "Ошибка. Проверьте правильность ввода электронной почты"
                );
                setErrorAlertShow(true);
            }
        }
    };

    const onChangePassword = async (e) => {
        e.preventDefault();
        setIsFieldPreparing(false);
        if (password) {
            try {
				console.log('password',password);
                const response = await changePassword(password, jwt_token)
                setAlertTitle("Пароль успешно изменен");
                //setAlertMessage('Перейдите на вашу почту, нажмите на ссылку в письме для смены пароля')
                setAccessAlertShow(true);
            } catch (error) {
                console.error(error);
                setAlertMessage(error.response);
                setErrorStatus(error.response?.status);
                setAlertTitle("Ошибка при смене пароля");
                setErrorAlertShow(true);
            }
        }
    };

    return (
        <div className="flex justify-center">
            <form className="border border-border p-10 pb-10">
                {isEnterEmail ? (
                    <>
                        <h1 className="text-center mb-10">Смена пароля</h1>
                        <p className="mb-6 text-center">
                            Мы отправим ссылку для смены пароля <br /> на ваш
                            email.
                        </p>
                        <Field
                            required={true}
                            placeholder="Ввести email"
                            className="mb-7 bg-grey p-3 rounded-2xl w-full"
                            type="email"
                            name="email"
                            Icon={AtSign}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            full="w-full"
                            style={
                                (isFieldPreparing || email) === ""
                                    ? VALIDATE_STYLE
                                    : {}
                            }
                        />
                        <div className="text-center">
                            <button
                                className="accent button button-accent"
                                onClick={onVerifyEmail}
                            >
                                Подтвердить email
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="text-center mb-10">Смена пароля</h1>
                        <Field
                            required={true}
                            placeholder="Ввести новый пароль"
                            error={{ message: "", type: "min" }}
                            className="mb-7 bg-grey p-3 rounded-2xl w-80 flex"
                            type="password"
                            name="password"
                            Icon={Eye}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={
                                (isFieldPreparing || password) === ""
                                    ? VALIDATE_STYLE
                                    : {}
                            }
                        />
                        <div className="text-center">
                            <button
                                className="accent button button-accent"
                                onClick={onChangePassword}
                            >
                                Сменить пароль
                            </button>
                        </div>
                    </>
                )}
            </form>

            <ErrorAlert
                open={errorAlertShow}
                setOpen={setErrorAlertShow}
                title={alertTitle}
                status={errorStatus}
                message={alertMessage}
            />
            <AccessAlert
                open={accessAlertShow}
                setOpen={setAccessAlertShow}
                alertTitle={alertTitle}
                alertMessage={alertMessage}
            />
        </div>
    );
};

export default ChangePasswordPage;
