import React, { useEffect } from "react";

const RadioButtons = ({ defaultValue = -1, items, isReset, setIsReset, onChangeCallback }) => {
  const [conditionValue, setConditionValue] = React.useState(defaultValue);
  useEffect(()=> {
    if(isReset) {
      setIsReset(false)
      setConditionValue(-1)
    }
  }, [isReset])
  return (
    <div className="flex flex-row w-full gap-3">
      {items.map((condition) => (
        <div
          key={condition.id + "condition-filter"}
          onClick={() => {
            setConditionValue(condition.id);
            onChangeCallback(condition.id);
          }}
          className={`
                             ${
                               conditionValue !== condition.id
                                 ? "bg-grey cursor-pointer"
                                 : "bg-accent color-white"
                             }
                                 "cursor-pointer w-1/2 flex p-3 rounded-xl mt-3 items-center justify-between outline-none"
                             `}
        >
          {condition.name}
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
