import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './BuyAPartSmall.module.scss'
import { ArrowUpRight } from "lucide-react";

const BuyAPartSmall = ({ icon, headerTitle, underTitle, pieces, tires, link }) => {
	return (
		<NavLink className={`w-full ${styles.wrap__linker}`} to={link}>
			<div className={[styles.wrap, styles.grey].join(' ')}>
				<div className={`${styles.wrap__down} flex flex-col`}>
					{underTitle}
				</div>
				<div className={`${styles.wrap__up__link} ${styles.move} py-1 px-2 rounded-xl mt-3 w-24 flex items-center justify-center`}>
					{headerTitle}
				</div>
			</div>
		</NavLink>
	)
}

export default BuyAPartSmall
