import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEVICE_ROUTE } from "../../../utils/consts";
import { useScreenSize } from "../../../utils/hooks/hooks";

const DeviceItem = ({ device }) => {
  const truncatedDescription =
    device.description.length > 300
      ? `${device.description.slice(0, 300)}...`
      : device.description;
  const history = useNavigate();

  const isTabletScreen = useScreenSize(1280)

  const [images, setImages] = useState(["/images/ajshdbjhb.jpg"]);
  useEffect(()=> {
    if (device?.images != null) {
      setImages(device?.images)
    }
  }, [device])

  return (
    <div className="w-full">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => history(DEVICE_ROUTE + "/" + device.id)}
        className="tr flex bg-transparent xl:hover:bg-grey/30 rounded-xl flex-col sm:flex-row gap-6"
      >
        <div
          className="bg-black z-0 rounded-xl w-full xl:w-96 h-60 object-cover object-center relative bg-center bg-cover"
          // style={{ backgroundImage: `url(${device.image})` }}></div>
          style={{
            backgroundImage: `url(${images[0] || "/images/ajshdbjhb.jpg"})`,
          }}
        ></div>
        <div className="w-full justify-between flex flex-col-reverse xl:flex-row sm:pb-12 sm:pr-6">
          <div>
            {
              !isTabletScreen && 
              <h1 className="title-l">
                {device.title}
              </h1>
            }
            <p className="m-text mt-3 sm:mt-0">{truncatedDescription}</p>
          </div>
          <div className="text-right">
            <div className="sm:pl-3">
              {
                isTabletScreen && 
                <h1 className="title-l text-left">
                  {device.title}
                </h1>
              }
              <h3 className="text-xl flex items-center gap-x-2">
                Цена: <span>{device.price}</span>
              </h3>
              <h3 className="text-xl flex items-center gap-x-2 mt-3 sm:mt-0">
                Состояние:{" "}
                <span style={{minWidth: 'max-content'}}>{device.condition === "new" ? "Новое" : "Б/у"}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceItem;
