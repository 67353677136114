import React from 'react'
import BusinessPage from '../../components/screens/business/Business'

const Business = () => {
	return (
		<div className='container'>
			<BusinessPage />
		</div>
	)
}

export default Business
