import React from 'react'
import Game from '../../components/screens/game/Game'

const GamePage = () => {
	return (
		<div className='container'>
			<Game />
		</div>
	)
}

export default GamePage
