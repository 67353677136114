import React from 'react'
import DeviceList from '../../ui/ShopDeviceList/ShopDeviceList'

const Shop = () => {

	return (
		<div>
			<DeviceList/>
		</div>
	)
}

export default Shop
