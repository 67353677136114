export const MAIN_ROUTE = '/'
export const ADMIN_ROUTE = '/admin'
export const ADMIN_PANEL_ROUTE = '/admin/panel'
export const BASKET_ROUTE = '/basket'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const BUSINESS_ROUTE = '/business'
export const SHOP_ROUTE = '/shop'
export const DEVICE_ROUTE = '/device'
export const CREATE_AN_AD = '/createanad'
export const USER_PROFILE_ADS = '/user-ads'
export const CHAT_ROUTE = '/room'
export const GAME_ROUTE = '/game'
export const CHANGE_PASSWORD = '/change-password'
export const MAILING_ROUTE = '/mailing'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'

export const VALIDATE_STYLE = {
	backgroundColor: 'rgba(255, 245, 245, 1)',
	border: '1px solid red',
}
