import {$authHost, $host} from ".";

export const registration = async (email, password, name, city, jwt_token) => {
    try {
        const response = await $host.post("/api/profile", {
            email: email,
            password: password,
            name: name,
            city: city,
            jwt_token: jwt_token,
        });
        return response;
    } catch (e) {
        console.error("Error:", e);
        throw e;
    }
};

export const login = async (email, password) => {
    try {
        const response = await $host.post("/api/auth/login", {
            email: email,
            password: password,
        });
        return response;
    } catch (e) {
        console.error("Error:", e.message);
        throw e;
    }
};

export const profileInfo = async (profile_id = null) => {
    try {
        let route =
            profile_id === null ? "/api/account" : "/api/account/" + profile_id;
        return await $authHost.get(route);
    } catch (e) {
        throw e
    }
};

export const getProfileNotifications = async () => {
    try {
        return await $authHost.get("/api/notifications");
    } catch (e) {
        console.error(e);
    }
};

export const deleteProfileNotifications = async (notification_id) => {
    try {
        return await $authHost.delete(
            `/api/notifications/${notification_id}`
        );
    } catch (e) {
        console.error(e);
    }
};

export const getRoom = async (profile_id) => {
    try {
        return await $authHost.get("/api/chat/rooms/" + profile_id);
    } catch (e) {
        console.error(e);
    }
};

export const getRooms = async () => {
    try {
        return await $authHost.get("/api/chat/rooms/");
    } catch (e) {
        console.error(e);
    }
};

export const check = async () => {
    try {
        return await $authHost.get("/api/profile");
    } catch (e) {
        console.error(e);
    }
};

export const handleLogout = async () => {
    try {
        const response = await $authHost.post("/api/auth/logout");
        return response;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const handleProfileProducts = async () => {
    try {
        const response = await $authHost.get("/api/profile/products");
        return response;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const handleUpdateProfile = async (name, city, isPhoneVisible, token) => {
    try {
        const updateProfileData = {
            name: name,
            jwt_token: token,
            city_id: city,
            is_phone_visible: isPhoneVisible
        };
        const response = await $authHost.patch(
            "/api/profile",
            JSON.stringify(updateProfileData)
        );
        return response;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const handleUpdateBusinessProfile = async (inn, ogrn, legalAddress, companyName) => {
    try {
        const updateBusinessProfileData = {
            inn: inn,
            ogrn: ogrn,
            legal_address: legalAddress,
            company_name: companyName
        };
        const response = await $authHost.patch(
            "/api/business-profile",
            JSON.stringify(updateBusinessProfileData)
        );
        return response;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const handleCreateProduct = async (
    price,
    address,
    title,
    description,
    condition,
    genId,
    type,
    image
) => {
    const createProductData = {
        price: price,
        address: address,
        title: title,
        condition: condition,
        description: description,
        car_gen_id: genId,
        detail_type_id: type,
        images: image,
    };

    try {
        const response = await $authHost.post(
            "/api/profile/products",
            JSON.stringify(createProductData)
        );
        return response;
    } catch (e) {
        console.error("Error create product:", e);
        throw e;
    }
};

export const editCreateProduct = async (
    productid,
    price,
    address,
    description,
    condition,
    genId,
    type
) => {
    const createProductData = {
        price: price,
        address: address,
        condition: condition,
        description: description,
        car_gen_id: genId,
        detail_type_id: type,
    };

    try {
        const response = await $authHost.patch(
            `/api/profile/products/${productid}`,
            JSON.stringify(createProductData)
        );
        return response;
    } catch (e) {
        console.error("Error create product:", e);
        throw e;
    }
};

export const deleteProduct = async (id) => {
    try {
        const response = await $authHost.delete(`/api/profile/products/${id}`);
        return response;
    } catch (e) {
        console.error("Error delete product:", e);
        throw e;
    }
};

export const sendSms = async (phone) => {
    try {
        const response = await $host.post("/api/auth/send-sms", {
            phone_number: phone,
        });
        return response;
    } catch (e) {
        console.error("Error in sending sms");
        throw e;
    }
};

export const confirmPhone = async (verification_id, verification_code) => {
    try {
        const response = await $host.post("/api/auth/confirm-phone", {
            verification_id: verification_id,
            verification_code: verification_code,
        });
        return response;
    } catch (e) {
        console.error("Verification code is not valid");
        throw e;
    }
};

export const sendChangePasswordToEmail = async (email) => {
    try {
        const response = await $host.post(
            "/api/profile/change-password-with-email",
            email
        );
        return response;
    } catch (e) {
        console.error("Error sending change password to email");
        throw e;
    }
};

export const changePassword = async (password, jwtToken) => {
    try {
        const response = await $host.post(
            `/api/profile/drop-password?jwt_token=${jwtToken}`,
            password
        );
        return response;
    } catch (e) {
        console.error("Error changing password");
        throw e;
    }
};

export const pinChatMessage = async (
    dest_id,
    image,
    title, 
    price,
    deviceId
) => {
    const pinData = {
        image: image,
        title: title,
        price: price,
        id: deviceId
    }

    const pinMessageData = {
        text: JSON.stringify(pinData), 
        dest_id: dest_id,
        pinned: true
    }

    try {
        const response = await $authHost.post(
            "/api/chat/rooms",
            JSON.stringify(pinMessageData)
        );
        return response;
    } catch (e) {
        console.error("Error pinning last message:", e);
        throw e;
    }
};

export const handleCreateBusiness = async (
    inn,
    ogrn,
    companyName,
    legalAddress,
) => {
    const createBusinessData = {
        inn: inn,
        ogrn: ogrn,
        company_name: companyName,
        legal_address: legalAddress,
    };

    try {
        const response = await $authHost.post(
            "/api/business-profile",
            JSON.stringify(createBusinessData)
        );
        return response;
    } catch (e) {
        console.error("Error create business:", e);
        throw e;
    }
};

export const blockChat = async (profile_id) => {
    try {
        return await $authHost.post(`/api/chat/block/${profile_id}`);
    } catch (e) {
        console.error("Error blocking chat:", e);
        throw e;
    }
};

export const unblockChat = async (profile_id) => {
    try {
        return await $authHost.delete(`/api/chat/block/${profile_id}`);
    } catch (e) {
        console.error("Error unblocking chat:", e);
        throw e;
    }
};

export const sendComplain = async (productId, complainMessage) => {
    try {
        return await $authHost.post(
            `/api/products/complaint/${productId}`,
            JSON.stringify(complainMessage)
        );
    } catch (e) {
        console.error("Error sending complain: ", e);
        throw e;
    }
};

