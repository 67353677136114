import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Context } from '../../..'
import { check } from '../../../http/userAPI'
import {
	CHANGE_PASSWORD,
	CHAT_ROUTE,
	CREATE_AN_AD,
	LOGIN_ROUTE,
	MAIN_ROUTE,
	PRIVACY_POLICY_ROUTE,
	REGISTRATION_ROUTE,
	SHOP_ROUTE,
	USER_PROFILE_ADS,
} from '../../../utils/consts'
import { useScreenSize } from '../../../utils/hooks/hooks'
import ProfileUser from '../../modals/ProfileUser'
import CustomNavLink from '../CustomNavLink/CustomNavLink'
import Logo from '../logo/Logo'

const Footer = observer(() => {
	const { user } = useContext(Context)

	const pathname = useLocation().pathname
	let isLocationChat = '/' + pathname.split('/')[1] === CHAT_ROUTE ? true : false
	let isLocationMain = pathname === MAIN_ROUTE ? true : false
	let isLocationMainNew = pathname === '/1' ? true : false
	let isLocationRegistration = pathname === REGISTRATION_ROUTE ? true : false
	let isLocationLogin = pathname === LOGIN_ROUTE ? true : false
	const isLocationChangePassword = pathname.includes(CHANGE_PASSWORD)
	const [openProfileModal, setOpenProfileModal] = useState(false)
	const isMobileScreen = useScreenSize(560)

	useEffect(() => {
		check()
			.then(data => {
				if (data?.status === 200) {
					user.setIsAuth(true)
					user.setId(data.data?.id)
				}
			})
			.catch(error => {
				user.setIsAuth(false)
			})
		window.scrollTo(0, 0)
	}, [pathname, user])

	return (
		<div 
			style={{ marginTop: 'auto' }} 
			className={`pt-9 pb-12 ${
				isLocationMain ||
				isLocationChat ||
				isLocationRegistration ||
				isLocationLogin ||
				isLocationChangePassword ||
				isLocationMainNew
					? 'hidden'
					: ''
			}`}>
			<div
				className={`container flex justify-center items-center ${
					isMobileScreen ? 'flex-col' : 'flex-row'
				} gap-10`}>
				<Logo />

				{user.isAuth ? (
					<div className='flex flex-row flex-wrap w-full items-center justify-center'>
						<CustomNavLink linkTo={CREATE_AN_AD} text={'Разместить объявление'} />
						<CustomNavLink linkTo={SHOP_ROUTE} text={'Купить деталь'} />
						<div
							type='button'
							onClick={() => {
								setOpenProfileModal(true)
							}}
							className='flex px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 w-36 items-center justify-center rounded-md text-center'>
							Мой профиль
						</div>
						<CustomNavLink linkTo={USER_PROFILE_ADS} text={'Мои объявления'} />

						<CustomNavLink linkTo={CHAT_ROUTE + `/${user.id}`} text={'Мои чаты'} />
					</div>
				) : (
					<div className='flex flex-row flex-wrap w-full items-center justify-center'>
						<CustomNavLink linkTo={REGISTRATION_ROUTE} text={'Зарегистрироваться'} />
						<CustomNavLink linkTo={LOGIN_ROUTE} text={'Войти'} />
						<CustomNavLink linkTo={SHOP_ROUTE} text={'Купить деталь'} />
						<CustomNavLink
							linkTo={PRIVACY_POLICY_ROUTE}
							text={'Политика конфиденциальности'}
						/>
					</div>
				)}

				<ProfileUser show={openProfileModal} onHide={setOpenProfileModal} />
			</div>
		</div>
	)
})

export default Footer
