import { useEffect, useState } from 'react'

const COOKIE_CONSENT_KEY = 'cookieConsent'

export default function CookiePopup() {
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		const consent = localStorage.getItem(COOKIE_CONSENT_KEY)
		if (!consent) {
			setIsVisible(true)
		}
	}, [])

	const acceptCookies = () => {
		localStorage.setItem(COOKIE_CONSENT_KEY, 'true')
		setIsVisible(false)
	}

	if (!isVisible) return null

	return (
		<div className='fixed bottom-4 max-w-[350px] right-4 md:left-auto z-50 bg-[#fff] p-4 shadow-xl rounded-lg flex flex-col items-center gap-4'>
			<p className='text-sm md:text-base flex-1'>
				Мы используем куки для улучшения работы сайта. Продолжая использовать сайт, вы
				соглашаетесь с этим.
			</p>
			<button
				onClick={acceptCookies}
				className='button button-accent tr hover:!bg-accent/80 hover:!text-white w-full'>
				Принять
			</button>
		</div>
	)
}
