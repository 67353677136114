import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../index";
import { useNavigate } from "react-router-dom";
import { fetchAllCategories, fetchCarsBrands } from "../../../http/carAndDetailSelectorAPI";
import { findItemById, resetSearchProducts } from "../../ui/ShopDeviceList/SearchUtils";
import { check, handleCreateBusiness, handleCreateProduct } from "../../../http/userAPI";
import { MAIN_ROUTE, USER_PROFILE_ADS, VALIDATE_STYLE } from "../../../utils/consts";
import RadioButtons from "../../ui/RadioButtons/RadioButtons";
import DropdownSearch from "../../ui/DropdownSearch/DropdownSearch";
import { setBrands, setCategories, setCategoriesTypes, setGeneration, setMarks } from "../../ui/Navbar/NavbarSelected/NavbarSelectedUtils";
import Dropdown from "../../ui/Dropdown/Dropdowns";
import ErrorAlert from "../../ui/modal/ErrorAlert/ErrorAlert";
import AccessAlert from "../../ui/modal/AccessAlert/AccessAlert";
import Field from "../../ui/Field/Field";

const CogForm = observer(() => {
  const { device, search, user } = useContext(Context);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [accessAlertShow, setAccessAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [errorStatus, setErrorStatus] = useState("");

  const [isFieldPreparing, setIsFieldPreparing] = useState(true)
  const [inn, setInn] = useState('')
  const [ogrn, setOgrn] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [legalAddress, setLegalAddress] = useState('')
  
  async function createBusiness(
    e,
    inn,
	ogrn,
	companyName,
	legalAddress
  ) {
    setIsFieldPreparing(false)
    e.preventDefault();
	if (!inn || !ogrn || !companyName || !legalAddress) return;
	handleCreateBusiness(inn, ogrn, companyName, legalAddress).then(res => {
		console.log(res.status)
		if (Number.parseInt(res?.status / 100) === 2) {
			setAlertTitle("Вы успешно сохранили данные!");
			setAccessAlertShow(true);
		}
	}).catch(error => {
		console.log(error)
		setAlertMessage(error.response);
      	setErrorStatus(error.response?.status);
      	setAlertTitle("Ошибка при сохранили данных");
      	setErrorAlertShow(true);
	})
  }

  return (
    <div className="flex justify-center pt-10">
      <form>
	  	<h1 className='text-center mb-10'>Подтверждение реквизитов <br /> компании</h1>
		<div className="flex flex-col justify-center">
			<Field
				required={true}
				placeholder='ИНН'
				className='pl-3 mb-7 bg-grey p-3 rounded-2xl w-full'
				type='text'
				name='inn'
				value={inn}
				onChange={e => setInn(e.target.value)}
				full='w-full'
				style={
					(isFieldPreparing || inn) === "" ? {...VALIDATE_STYLE, paddingLeft: '12px'} : {paddingLeft: '22px'}
				}
			/>
			<Field
				required={true}
				placeholder='ОГРН'
				className='pl-3 mb-7 bg-grey p-3 rounded-2xl w-full'
				type='text'
				name='ogrn'
				value={ogrn}
				onChange={e => setOgrn(e.target.value)}
				full='w-full'
				style={
					(isFieldPreparing || ogrn) === "" ? {...VALIDATE_STYLE, paddingLeft: '12px'} : {paddingLeft: '22px'}
				}
			/>
			<Field
				required={true}
				placeholder='Название'
				className='pl-3 mb-7 bg-grey p-3 rounded-2xl w-full'
				type='text'
				name='name'
				value={companyName}
				onChange={e => setCompanyName(e.target.value)}
				full='w-full'
				style={
					(isFieldPreparing || companyName) === "" ? {...VALIDATE_STYLE, paddingLeft: '12px'} : {paddingLeft: '22px'}
				}
			/>
			<Field
				required={true}
				placeholder='Юредический адрес'
				className='pl-3 mb-7 bg-grey p-3 rounded-2xl w-full'
				type='text'
				name='name'
				value={legalAddress}
				onChange={e => setLegalAddress(e.target.value)}
				full='w-full'
				style={
					(isFieldPreparing || legalAddress) === "" ? {...VALIDATE_STYLE, paddingLeft: '12px'} : {paddingLeft: '22px'}
				}
			/>
		</div>

        <div className="text-center">
              <button
                  className="button button-accent"
                  onClick={(e) =>
                      createBusiness(
						e,
						inn,
						ogrn,
						companyName,
						legalAddress
                  )
                }
              >
                Подтвердить реквизиты
              </button>

        </div>
      </form>

      <ErrorAlert
        open={errorAlertShow}
        setOpen={setErrorAlertShow}
        title={alertTitle}
        status={errorStatus}
        message={alertMessage}
      />
      <AccessAlert
        open={accessAlertShow}
        setOpen={setAccessAlertShow}
        alertTitle={alertTitle}
        alertMessage={alertMessage}
		func={() => window.location = "/"}
      />
    </div>
  );
});

export default CogForm;
