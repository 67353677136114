import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import EditProduct from '../../components/modals/EditProduct'
import DeviceItem from '../../components/ui/DeviceItem/DeviceItem'
import { deleteProduct, handleProfileProducts } from '../../http/userAPI'
import { CREATE_AN_AD } from '../../utils/consts'
import QuestionAlert from '../../components/ui/modal/QuestionAlert/QuestionAlert'

const UserProfileAds = observer(() => {
	const { user } = useContext(Context)
	const [openEditModal, setOpenEditModal] = useState(false)
	const [productActive, setProductActive] = useState('')
	const [accessAlertShow, setAccessAlertShow] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertTitle, setAlertTitle] = useState("");
	const [submitTitle, setSubmitTitle] = useState("")
	const [productToDelete, setProductToDelete] = useState("")

	useEffect(() => {
		if (user.isAuth) {
			handleProfileProducts().then(data => {
				user.setUserProducts(data.data)
			})
		} else {
			user.setUserProducts([])
		}
	}, [user, user.isAuth, openEditModal])

	const handleDeleteProduct = (productId) => {
		setAccessAlertShow(true)
		setAlertTitle("Вы уверены, что хотите удалить объявление?")
		setAlertMessage("Объявление будет удалено навсегда")
		setSubmitTitle("Удалить")
		setProductToDelete(productId)
	}

	const onDeleteProduct = async (productId) => {
		try {
			await deleteProduct(productId)
			const newProducts = user.userProducts.filter(product => product.id !== productId)
			user.setUserProducts(newProducts)
		} catch (error) {
			console.error(`Error deleting product ${productId}:`, error)
		}
	}


	function handleEditProduct(device) {
		setProductActive(device)
		setOpenEditModal(true)
	}

	return (
		<div className='container'>
			{user.userProducts.length >= 1 ? (
				<div className='mx-auto pb-12 flex flex-col justify-between group relative w-full border-t'>
					{user.userProducts.map(device => (
						<div
							className='flex border-gray-900\/10 pt-3 pb-3 w-full flex-col xl:flex-row'
							key={device.id}>
							<div className='w-full border-gray-900\/10'>
								<DeviceItem key={device.id} device={device} />
							</div>
							<div className='xl:border-l sm:ml-6 relative xl:pl-6 pt-6'>
								<div className='w-full border-gray-900\/10 border-b pb-6 sm:pb-0 sm:pl-6 sm:pr-6 h-full'>
									<div className='flex gap-y-6 flex-col'>
										<button
											type='button'
											onClick={() => handleEditProduct(device)}
											className='button'>
											Изменить
										</button>
										<button
											type='button'
											className='button'
											onClick={() => {												
												handleDeleteProduct(device.id)
											} }>
											Удалить
										</button>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className='text-center flex items-center flex-col justify-center border-t'>
					<h2 className='title mt-12'>Пока тут ничего нет...</h2>
					<p className='m-text mt-3'>
						В данный момент здесь нет ни одного объявления, но вы можете добавить его.
					</p>
					<NavLink to={CREATE_AN_AD} className='button mt-12'>
						Разместить объявление
					</NavLink>
				</div>
			)}

			<EditProduct show={openEditModal} onHide={setOpenEditModal} productActive={productActive} />
			<QuestionAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
				submitTitle={submitTitle}
				continueCallBack={()=> {
					onDeleteProduct(productToDelete)
				}}
			/>
		</div>
	)
})

export default UserProfileAds
