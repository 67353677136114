import { makeAutoObservable } from 'mobx'

export default class DeviceStore {
	constructor() {
		this._types = []
		this._brands = []

		this._generations = []

		this._categories = []
		this._categoriesTypes = []
		this._conditions = [
			{ id: 1, name: 'Новое', value: 'new' },
			{ id: 2, name: 'Б/У', value: 'used' },
		]
		this._sortings = [
			{ id: 1, name: 'По времени публикации', value: 'time', desc: false },
			{ id: 2, name: 'По времени публикации', value: 'time', desc: true },
			{ id: 3, name: 'По цене', value: 'price', desc: false},
			{ id: 4, name: 'По цене', value: 'price', desc: true },
			{ id: 5, name: 'По популярности', value: 'score', desc: false },
			{ id: 6, name: 'По популярности', value: 'score', desc: true },
		]
		this._devices = []
		this._selectedType = {}
		this._selectedBrand = {}
		makeAutoObservable(this)
	}

	setTypes(types) {
		this._types = types
	}

	setBrands(brands) {
		this._brands = brands
	}

	setGenerations(generations) {
		this._generations = generations
	}

	setSortings(sortings) {
		this._sortings = sortings
	}

	setCategories(categories) {
		this._categories = categories
	}

	setDevice(devices) {
		this._devices = devices
	}

	setCondition(conditions) {
		this._conditions = conditions
	}

	setCategoriesTypes(types) {
		this._categoriesTypes = types
	}

	setSelectedType(type) {
		this._selectedType = type
	}

	setSelectedBrand(brand) {
		this._selectedBrand = brand
	}

	get types() {
		return this._types
	}

	get conditions() {
		return this._conditions
	}

	get sortings() {
		return this._sortings
	}

	get brands() {
		return this._brands
	}

	get devices() {
		return this._devices
	}

	get generations() {
		return this._generations
	}

	get categories() {
		return this._categories
	}

	get categoriesTypes() {
		return this._categoriesTypes
	}

	get selectedType() {
		return this._selectedType
	}

	get selectedBrand() {
		return this._selectedBrand
	}
}
