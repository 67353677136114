import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {Fragment, useEffect, useRef, useState} from 'react'
import { confirmPhone } from '../../../../http/userAPI'
import ErrorAlert from "../ErrorAlert/ErrorAlert";

export default function PhoneVerification({open, setOpen, verificationId, handleRegister}) {
    const cancelButtonRef = useRef(null)
    const inputRefs = useRef(Array(6).fill(null));
    const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', ''])
    const [isCodeValid, setIsCodeValid] = useState(false)
    const handleKeyDown = (event) => {
        setTimeout(()=> {
            if (event.key === 'Backspace') {
                const index = inputRefs.current.indexOf(event.target)
                if (index !== 0) {
                    inputRefs.current[index - 1].focus()
                    inputRefs.current[index - 1].setSelectionRange(1, 1);
                }
            } else if (event.key >= '0' && event.key <= '9') {
                const index = inputRefs.current.indexOf(event.target)
                if (index !== inputRefs.current.length-1) {
                    inputRefs.current[index + 1].focus()
                    inputRefs.current[index + 1].setSelectionRange(1, 1);
                }
            } 
        }, 50)
      };

    useEffect(() => {    
        if(open === true) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
      }, [open]);    

    const handleCodeChange = async (ev, index) => {
        let sym = ev.target.value;
        let newCode = [...verificationCode]

        if (navigator.clipboard && sym === "V") {
            let text = await navigator.clipboard.readText();
            newCode = [...text.substring(0, 6)]
            setVerificationCode(newCode)
        }

        if (" 0123456789".includes(sym)) {
            newCode[index] = sym;
            setVerificationCode(newCode)
        }

        // Validate the code
        if (newCode.every(code => code !== '')) {
            setIsCodeValid(true)
        } else {
            setIsCodeValid(false)
        }
    }

    const handleVerify = async e => {
        try {
            e.preventDefault()
            const response = await confirmPhone(verificationId, verificationCode.join(''));
            const token = response.data.jwt_token;
            await handleRegister(token)
            setVerificationCode(['', '', '', '', '', ''])
            setOpen(false)
        } catch(error) {
            console.error(error)
            setAlertMessage(error.response)
            setErrorStatus(error.response?.status)
            setAlertTitle('Ошибка верификации')
            setErrorAlertShow(true)
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'>
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                    <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                                <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                                    <div className='flex items-center mb-3 gap-x-6'>
                                        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                                            <ExclamationTriangleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <Dialog.Title
                                            as='h3'
                                            className='text-base font-semibold leading-6 text-gray-900'>
                                            Подтверждение номера телефона
                                        </Dialog.Title>
                                    </div>
                                    <div className='sm:flex sm:items-start'>
                                        <div className='text-center sm:ml-4 sm:mt-0 sm:text-left'>
                                            <div className='mt-2'>
                                                <p>Введите 6-тизначный код из смс:</p>
                                                <div className='flex gap-2 mt-4'>
                                                    {verificationCode.map((code, index) => (
                                                        <input
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                            key={index}
                                                            value={code}
                                                            inputMode='numeric'
                                                            type="text"
                                                            onInput={async (e) => await handleCodeChange(e, index)}
                                                            maxLength={1}
                                                            className='w-10 h-10 text-center border border-gray-300 rounded-md'
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                                    <button
                                        type='button'
                                        className={`inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto ${isCodeValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                                        onClick={handleVerify}
                                        disabled={!isCodeValid}>
                                        Подтвердить
                                    </button>
                                    <button
                                        type='button'
                                        className='mt-3 inline-flex w-full justify-center outline-none rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto hover:bg-accent-300'
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}>
                                        Отмена
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    <ErrorAlert
                        open={errorAlertShow}
                        setOpen={setErrorAlertShow}
                        title={alertTitle}
                        status={errorStatus}
                        message={alertMessage}
                        isLogin={false}
                    />
                </div>
            </Dialog>
        </Transition.Root>
    )
}
