import React, { useEffect, useState } from 'react'

const RenderBreadcrumbs = ({details}) => {

	const [breadcrumbs, setBreadcrumbs] = useState([
		{ label: 'Главная', link: '/' },
		{ label: 'Каталог', link: '/shop' },
		{ label: '', link: '/' },
	])

	useEffect(()=> {
		if(details[details.length-1].value) {
			const lastBreadCrump = `${details[details.length-1].value} ${details[2].value} ${details[3].value} ${details[4].value}`
			setBreadcrumbs([
				{ label: 'Главная', link: '/' },
				{ label: 'Каталог', link: '/shop' },
				{ label: lastBreadCrump, link: '/' },
			])
		}
	}, [details])

	return (
		<div>
			{breadcrumbs.map((item, index) => (
				<React.Fragment key={index}>
					{index > 0 && <span className='mx-2'>/</span>}
					{index === breadcrumbs.length - 1 ? (
						<span className='font-semibold text-accent'>{item.label}</span>
					) : (
						<a href={item.link || '#'} className='tr hover:text-accent'>
							{item.label}
						</a>
					)}
				</React.Fragment>
			))}
		</div>
	)
}

export default RenderBreadcrumbs
