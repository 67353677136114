import styles from './Loader.module.css';

const Loader = () => {
	return (
		<div className={styles.loader} data-testid="loading">
			<span className={styles.spinner}></span>
		</div>
	);
};

export default Loader;
