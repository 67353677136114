import React, { useState, useContext } from 'react'
import Dropdown from '../Dropdown/Dropdowns'
import Field from '../Field/Field'
import NavbarSelected from '../Navbar/NavbarSelected/NavbarSelected'
import { resetSearchProducts, searchProducts } from './SearchUtils'
import styles from './ShopDeviceList.module.scss'
import RadioButtons from '../RadioButtons/RadioButtons'
import {Context} from "../../../index";

const FilterSortPanel = ({
	isTabletScreen,
	setIsFilterVisible,
	cityRepresentation,
	setCityRepresentation,
	locations
}) => {
	const {device, search} = useContext(Context);
	const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(true)
	const [isSortDropdownVisible, setIsSortDropdownVisible] = useState(false)
	const [minPriceFilter, setMinPriceFilter] = useState('')
	const [maxPriceFilter, setMaxPriceFilter] = useState('')
	const [isReset, setIsReset] = useState(false)

	const onSearch = (isMinPriceChanged = false, isMaxPriceChanged = false, minPrice = null, maxPrice = null) => {
		setIsFilterVisible(false)
		search.setPage(1);
		if (isMinPriceChanged) {
			search.setMinPriceFilter(minPrice)
		} 
		if (isMaxPriceChanged) {
			search.setMaxPriceFilter(maxPrice)
		}
		searchProducts(search, device)
	}

	return (
		<div className='flex flex-col gap-3'>
			<div className='flex flex-row justify-between gap-10'>
				<div className='flex flex-row gap-9'>
					{' '}
					<button
						className={` rounded-xl flex items-center justify-center ${
							isTabletScreen ? '' : 'hidden'
						} ${styles.lightAccent}`}
						style={{ minWidth: '49.2px' }}
						onClick={() => {
							setIsFilterVisible(false)
						}}>
						<svg
							className={`${styles.customSvg}`}
							width='45px'
							height='45px'
							viewBox='0 -0.5 25 25'
							xmlns='http://www.w3.org/2000/svg'>
							<path d='M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z' />
						</svg>
					</button>
				</div>
			</div>
			<button
				className={`${styles.customButton} ${styles.lightAccent} ${styles.buttonWithArrow} rounded-xl relative flex justify-between w-full`}
				onClick={() => {
					if (isFilterDropdownVisible && !isSortDropdownVisible) {
						setIsFilterDropdownVisible(false)
						setIsSortDropdownVisible(false)
					} else {
						setIsFilterDropdownVisible(true)
						setIsSortDropdownVisible(false)
					}
				}}>
				<span>Фильтры</span>
				<svg
					className={`${styles.customArrow} ${
						isFilterDropdownVisible ? styles.rotateCustomArrow : ''
					} relative w-7 h-7 left-3`}
					viewBox='0 0 24 24'
					fill='none'
					style={{ bottom: '2px' }}
					xmlns='http://www.w3.org/2000/svg'
					transform='rotate(-225)'
					stroke='#ffffff'>
					<g id='SVGRepo_bgCarrier' strokeWidth='0' />
					<g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
					<g id='SVGRepo_iconCarrier'>
						{' '}
						<g clipPath='url(#clip0_429_11220)'>
							{' '}
							<path
								d='M16 16L16 8L8 8'
								strokeWidth='1.4'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>{' '}
						</g>{' '}
						<defs>
							{' '}
							<clipPath id='clip0_429_11220'>
								{' '}
								<rect width='38' height='38' />{' '}
							</clipPath>{' '}
						</defs>{' '}
					</g>
				</svg>
			</button>
			<div className={`block ${isFilterDropdownVisible ? '' : 'hidden'}`}>
				<NavbarSelected/>
			</div>
			<button
				className={`${styles.customButton} ${styles.lightAccent} ${styles.buttonWithArrow} rounded-xl relative flex justify-between w-full`}
				onClick={() => {
					if (isSortDropdownVisible && !isFilterDropdownVisible) {
						setIsFilterDropdownVisible(false)
						setIsSortDropdownVisible(false)
					} else {
						setIsFilterDropdownVisible(false)
						setIsSortDropdownVisible(true)
					}
				}}>
				<span>Сортировка</span>
				<svg
					className={`${styles.customArrow} ${
						isSortDropdownVisible ? styles.rotateCustomArrow : ''
					} relative w-7 h-7 left-3`}
					viewBox='0 0 24 24'
					fill='none'
					style={{ bottom: '2px' }}
					xmlns='http://www.w3.org/2000/svg'
					transform='rotate(-225)'
					stroke='#ffffff'>
					<g id='SVGRepo_bgCarrier' strokeWidth='0' />
					<g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
					<g id='SVGRepo_iconCarrier'>
						{' '}
						<g clipPath='url(#clip0_429_11220)'>
							{' '}
							<path
								d='M16 16L16 8L8 8'
								strokeWidth='1.4'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>{' '}
						</g>{' '}
						<defs>
							{' '}
							<clipPath id='clip0_429_11220'>
								{' '}
								<rect width='38' height='38' />{' '}
							</clipPath>{' '}
						</defs>{' '}
					</g>
				</svg>
			</button>
			<div style={{
				height: isSortDropdownVisible ? "auto" : "0px",
				overflowY: isSortDropdownVisible ? 'initial' : 'hidden'
			}}>
				<div className='flex flex-col'>
					<div className='flex flex-col w-full'>
						<div className='flex gap-x-3'>
							<input
								className='flex p-3 bg-grey w-1/2 rounded-xl mt-3 items-center justify-between placeholder:text-dark outline-none'
								value={minPriceFilter}
								onChange={e => {
									if (Number(e.target.value) > -1 && e.target.value !== "0" && Number(e.target.value) < 1000000000) {
										setMinPriceFilter(e.target.value)
										onSearch(true, false, e.target.value)
									}
								}}
								placeholder='Цена от'
							/>
							<input
								className='flex p-3 bg-grey w-1/2 rounded-xl mt-3 items-center justify-between placeholder:text-dark outline-none'
								value={maxPriceFilter}
								onChange={e => {
									if (Number(e.target.value) > -1 && e.target.value !== "0" && Number(e.target.value) < 1000000000) {
										setMaxPriceFilter(e.target.value)
										onSearch(false, true, null, e.target.value)
									}
								}}
								placeholder='Цена до'
							/>
						</div>

						<Field
							required={true}
							placeholder='Город'
							className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
							type='city'
							clearField={()=> {
								setCityRepresentation('')
								search.setCityFilter(null)
								onSearch()
							}}
							items={locations}
							value={cityRepresentation}
							onChange={e => {
								setCityRepresentation(e.target.value)
								search.setCityFilter(null)
							}}
							func={(city_id, city_repr) => {
								search.setCityFilter(city_id)
								setCityRepresentation(city_repr)
								onSearch()
								search.setPage(1);
							}}
							full='w-11/12'
						/>

						<RadioButtons 
							items={device.conditions} 
							isReset={isReset} 
							setIsReset={setIsReset} 
							onChangeCallback={(selectedValue)=>{
								search.setConditionFilter(selectedValue)
								onSearch()
							}}
						/>

						<Dropdown
							key={`generationSortFilter-${search.keyCounter}`}
							items={device.sortings}
							name={'Сортировка'}
							className='w-full'
							fullWidth={true}
							isPageShop={true}
							type={true}
							onChangeCallback={(sortFilter, descFilter) => {
								search.setSortFilter(sortFilter)
								search.setDescFilter(descFilter)
								onSearch()
							}}
						/>
					</div>
				</div>
			</div>
			<div className='flex gap-x-3 mt-6'>

				<button
					className={`w-full rounded-xl ${styles.lightAccent} ${styles.customButton}`}
					onClick={() => {
						resetSearchProducts(search, device)
						setCityRepresentation('')
						setIsReset(true)
						setMinPriceFilter('')
						setMaxPriceFilter('')
					}}>
					Очистить
				</button>
			</div>
		</div>
	)
}

export default FilterSortPanel
