import { $host } from '.'

export const fetchRegions = async params => {
	try {
		const { data } = await $host.get('/api/locations', {
			params: params,
		})
		return data
	} catch (e) {
		console.error(e)
		throw e
	}
}