import { lazy } from 'react'
import Admin from './app/Admin/page'
import { default as Auth } from './app/Auth/page'
import Basket from './app/Basket/page'
import Business from './app/Business/page'
import ChangePassword from './app/ChangePassword/ChangePassword'
import CreateAnAd from './app/CreateAnAd/page'
import DevicePage from './app/DevicePage/page'
import Game from './app/Game/page'
import Mailing from './app/Mailing/page'
import Main from './app/Main/page'
import PrivacyPolicy from './app/Privacy-policy/page'
import Shop from './app/Shop/page'
import UserProfileAds from './app/UserProfile/page'
import {
	ADMIN_PANEL_ROUTE,
	ADMIN_ROUTE,
	BASKET_ROUTE,
	BUSINESS_ROUTE,
	CHANGE_PASSWORD,
	CHAT_ROUTE,
	CREATE_AN_AD,
	DEVICE_ROUTE,
	GAME_ROUTE,
	LOGIN_ROUTE,
	MAILING_ROUTE,
	MAIN_ROUTE,
	PRIVACY_POLICY_ROUTE,
	REGISTRATION_ROUTE,
	SHOP_ROUTE,
	USER_PROFILE_ADS,
} from './utils/consts'

const Chat = lazy(() => import('./app/Chat/page'))

export const authRoutes = [
	{
		path: BASKET_ROUTE,
		Component: Basket,
	},
]

export const publicRoutes = [
	{
		path: MAIN_ROUTE,
		Component: Main,
	},
	{
		path: BUSINESS_ROUTE,
		Component: Business,
	},
	{
		path: GAME_ROUTE,
		Component: Game,
	},
	{
		path: ADMIN_ROUTE,
		Component: Auth,
	},
	{
		path: ADMIN_PANEL_ROUTE,
		Component: Admin,
	},
	{
		path: LOGIN_ROUTE,
		Component: Auth,
	},
	{
		path: REGISTRATION_ROUTE,
		Component: Auth,
	},
	{
		path: CREATE_AN_AD,
		Component: CreateAnAd,
	},
	{
		path: SHOP_ROUTE,
		Component: Shop,
	},
	{
		path: USER_PROFILE_ADS,
		Component: UserProfileAds,
	},
	{
		path: MAILING_ROUTE,
		Component: Mailing,
	},
	{
		path: PRIVACY_POLICY_ROUTE,
		Component: PrivacyPolicy,
	},
	{
		path: DEVICE_ROUTE + '/:id',
		Component: DevicePage,
	},
	{
		path: MAILING_ROUTE,
		Component: Mailing,
	},
	{
		path: PRIVACY_POLICY_ROUTE,
		Component: PrivacyPolicy,
	},
	{
		path: CHAT_ROUTE + '/:dest_id',
		Component: Chat,
	},
	{
		path: CHANGE_PASSWORD,
		Component: ChangePassword,
	},
	{
		path: CHANGE_PASSWORD + '/:jwt_token',
		Component: ChangePassword,
	},
]
