import React from 'react'

const PrivacyPolicy = () => {
	return (
		<div className='container'>
			<h1 className='text-xl md:text-2xl'>Согласие на обработку персональных данных</h1>

			<p className='mt-10'>
				Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от
				27.07.2006 г. свободно, своей волей, в своем интересе и подтверждая свою
				дееспособность, выражаю свое безусловное согласие на обработку моих персональных
				данных Обществу с ограниченной ответственностью «ГИР СТОР», зарегистрированному по
				адресу: 601443, Владимирская область, Вязниковский р-н, г. Вязники, ул. Ленина, стр.
				46 (далее по тексту — Оператор).
			</p>

			<h2 className='mt-5 text-xl font-bold'>Обрабатываемые персональные данные</h2>
			<ul className='mt-3'>
				<li>Фамилия, имя</li>
				<li>Адрес электронной почты</li>
				<li>Номер телефона</li>
				<li>
					Данные, собираемые сервисами сайта автоматически (IP-адрес, данные о
					местоположении и другие)
				</li>
			</ul>

			<h2 className='mt-5 text-xl font-bold'>Действия с персональными данными</h2>
			<p className='mt-3'>
				Оператор вправе совершать следующие действия с моими персональными данными с
				использованием средств автоматизации и/или без использования таких средств:
			</p>
			<ul className='mt-3'>
				<li>
					Сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
					изменение)
				</li>
				<li>
					Извлечение, использование, передача партнерам для исполнения принятых
					обязательств
				</li>
				<li>Обезличивание, блокирование, удаление, уничтожение</li>
				<li>
					Осуществление иных действий, если это предусмотрено действующим
					законодательством РФ
				</li>
			</ul>

			<h2 className='mt-5 text-xl font-bold'>Цели обработки персональных данных</h2>
			<ul className='mt-3'>
				<li>Предоставление доступа к сайту, его содержанию, к функционалу</li>
				<li>Идентификация при использовании сайта</li>
				<li>Установление обратной связи, включая направление уведомлений и запросов</li>
				<li>
					Заключение и исполнение договоров, предоставление услуг, осуществление расчетов
				</li>
				<li>Сбор Оператором статистики</li>
				<li>
					Улучшение качества работы сайта, удобства их использования и разработка новых
					сервисов и услуг
				</li>
				<li>
					Проведение маркетинговых (рекламных) мероприятий, направление предложений,
					информационной и рекламной рассылки
				</li>
			</ul>

			<h2 className='mt-5 text-xl font-bold'>Срок действия согласия</h2>
			<p className='mt-3'>
				Настоящее согласие действует с момента моего перехода на сайт Оператора до момента
				его отзыва мной путем направления соответствующего уведомления на электронный адрес
				Оператора или до наступления иных оснований, предусмотренных документом Оператора об
				обработке персональных данных.
			</p>

			<p>
				В случае отзыва мною согласия на обработку персональных данных Оператор вправе
				продолжить обработку персональных данных без моего согласия только при наличии
				оснований, прямо предусмотренных действующим законодательством РФ.
			</p>
		</div>
	)
}

export default PrivacyPolicy
