import { PhotoIcon } from "@heroicons/react/24/outline";
import styles from "./AddPhoto.module.scss";
import React, { useState, useRef } from "react";
import { handleAddPhoto, handleDrop } from "../../../utils/AddPhotoUtils";
import PhotoItems from "./PhotoItems";

const AddPhoto = ({ 
    setImage, 
    image, 
    tagStyle="",
    photos,
    setPhotos
  }) => {

  const fileInputRef = useRef(null);

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`${styles.addPhotosContainer}`}>
      <div className={styles.addPhotosTitle}>
        {photos.length === 0 ? (
          <>
          </>
        ) : (
          <>
            <label>Фотографии</label>
            <label className={`${styles.fontGrayThin} ${styles.imagesCounter}`}>
              Загружено: {photos.length}/8
            </label>
          </>
        )}
      </div>
      <div
        className={styles.addPhotosWrapper}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(event) => {handleDrop(event, photos, setPhotos, setImage)}}
        style={{...tagStyle, borderRadius: '10px'}}
      >
        {photos.length === 0 && (
          <>
                    <div className={styles.addFirstPhotoWrapper} onClick={openFilePicker}>
            <label>Выберите или перетащите фотографии в область</label>
            <label className={styles.fontGrayThin}>
              Форматы JPEG, JPG или PNG до 10 МБ каждый
            </label>
            <input
              ref={fileInputRef}
              type="file"
              accept='image/*'
              multiple
              onChange={ (event)=> {handleAddPhoto(event, photos, setPhotos, setImage)}}
              style={{ display: "none" }}
            />
            <button className={styles.addPhotoButton} type="button">
              Выбрать фотографии
            </button>
          </div>
          </>

          
        )}
        <div className={styles.photosWrapper}>
            <PhotoItems photos={photos} setPhotos={setPhotos} setImage={setImage}/>
          {photos.length > 0 && photos.length < 8 && (
            <div
              className={`${styles.addMorePhotos} group`}
              onClick={openFilePicker}
            >
              <label>
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300 group-hover:text-accent"
                  aria-hidden="true"
                />
              </label>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                multiple
                onChange={(event)=> {handleAddPhoto(event, photos, setPhotos, setImage)}}
                style={{ display: "none" }}
              />
              <button
                className={styles.addMorePhotoButton}
                type="button"
                onClick={openFilePicker}
              >
                Выбрать фото
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPhoto;
