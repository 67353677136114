import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../..";
import {
  fetchAllCategories,
  fetchCarsBrands,
} from "../../../../http/carAndDetailSelectorAPI";
import { check, handleCreateProduct } from "../../../../http/userAPI";
import FileUpload from "../../../FileUpload/FileUpload";
import Dropdown from "../../../ui/Dropdown/Dropdowns";
import {
  setBrands,
  setCategories,
  setCategoriesTypes,
  setGeneration,
  setMarks,
} from "../../../ui/Navbar/NavbarSelected/NavbarSelectedUtils";
import {
  findItemById,
  resetSearchProducts,
} from "../../../ui/ShopDeviceList/SearchUtils";
import AccessAlert from "../../../ui/modal/AccessAlert/AccessAlert";
import ErrorAlert from "../../../ui/modal/ErrorAlert/ErrorAlert";
import AddPhoto from "../../../ui/AddPhoto/AddPhoto";
import RadioButtons from "../../../ui/RadioButtons/RadioButtons";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTE, USER_PROFILE_ADS, VALIDATE_STYLE } from "../../../../utils/consts";
import DropdownSearch from "../../../ui/DropdownSearch/DropdownSearch";

const CogForm = observer(() => {
  const { device, search, user } = useContext(Context);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [accessAlertShow, setAccessAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [errorStatus, setErrorStatus] = useState("");

  useEffect(() => {
    fetchCarsBrands().then((data) => device.setTypes(data));
    fetchAllCategories().then((data) => device.setCategories(data));
    resetSearchProducts(search, device);
    if (!user.isAuth) {
      check().then((data) => {
        if (!data || !data?.status === 200) {
          setErrorStatus("Unauthorized");
          setAlertTitle(
            "Вы сможете создать объявление только после регистрации"
          );
          setErrorAlertShow(true);
        }
      });
    }
  }, [device, search, user]);

  const [price, setPrice] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [isDetailCreated, setIsDetailCreated] = useState(false)
  const [image, setImage] = useState([]);
  const [photos, setPhotos] = useState([]);
  const title = "no title in component";

  const [isFieldPreparing, setIsFieldPreparing] = useState(true);
  const [markValidate, setMarkValidate] = useState('')
  const [brandValidate, setBrandValidate] = useState('')
  const [generationValidate, setGenerationValidate] = useState('')
  const [categoriesValidate, setCategoriesValidate] = useState('')
  const [categoriesTypesValidate, setCategoriesTypesValidate] = useState('')

  useEffect(() => {
    check().then((data) => {
      if (data) {
        setAddress(
          () => data?.data?.city?.region?.name + " " + data?.data?.city?.name
        );
      }
    });
  }, []);
  
  const history = useNavigate();
  async function createProduct(
    e,
    price,
    address,
    title,
    description,
    genId,
    type,
    image
  ) {
    setIsFieldPreparing(false)
    e.preventDefault();
    try {
      console.log('markValidate', markValidate);
      if (
        price &&
        address &&
        description &&
        image.length &&
        markValidate &&
        brandValidate &&
        generationValidate &&
        categoriesValidate &&
        categoriesTypesValidate 
      ) {
        let condition = findItemById(device?.conditions, search.conditionFilter);
        await handleCreateProduct(
          Number(price.replace(/\D/g, "")),
          address,
          `${categoriesTypesValidate} ${markValidate} ${brandValidate} ${generationValidate}`,
          description,
          condition?.value,
          genId,
          type,
          image
        );
        setAlertTitle("Вы успешно создали товар!");
        setAccessAlertShow(true);
        setIsDetailCreated(true)
      }
    } catch (error) {
      setAlertMessage(error.response);
      setErrorStatus(error.response?.status);
      setAlertTitle("Ошибка при создании объявления");
      setErrorAlertShow(true);
    }
  }

  function resetFormProducts(device, search) {
    setIsFieldPreparing(true)
    setPrice("");
    setAddress("");
    setDescription("");
    setImage([]);
    setPhotos([])
    resetSearchProducts(device, search);
    setMarkValidate('')
    setBrandValidate('')
    setGenerationValidate('')
    setCategoriesValidate('')
    setCategoriesTypesValidate('')
  }

  //set on old condition by default
  useEffect(() => {
    search.setConditionFilter(device.conditions[0].id);
  }, [])

  const inputRef = useRef(null);

  const handleChange = (e) => {
    let onlyDigits = e.target.value.replace(/\D/g, "");
    if (onlyDigits !== "" && Number(onlyDigits) < 1) {
      onlyDigits = 1
    }
    if (onlyDigits < 1000000000) {
      setPrice(onlyDigits);
    }
  };

  const handleFocus = () => {
    setPrice(price.replace(/\D/g, ""));
  };

  const handleBlur = () => {
    if (price) {
      setPrice(`${price} руб.`);
    }
  };

  return (
    <div className="pb-12">
      <form>
        <div className="space-y-12 text-center">
          <div className="pb-12 border-b border-gray-900\/10">
            <h2 className="title">Подать объявление о продаже запчасти</h2>

            <div className="flex justify-between relative mt-12 flex-col-reverse xl:flex-row">
              <div className="xl:w-1/2 xl:pr-3">
              <input
                ref={inputRef}
                className="flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none"
                placeholder="Цена"
                type="text"
                value={price}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={
                    (isFieldPreparing || price) === "" ? VALIDATE_STYLE : {}
                }
              />

                <RadioButtons defaultValue={device.conditions[0].id} items={device.conditions} onChangeCallback={(selectedValue)=>{search.setConditionFilter(selectedValue)}}/>

                <textarea
                    className="flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none"
                    placeholder="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={
                      (isFieldPreparing || description) === "" ? VALIDATE_STYLE : {}
                  }
                />

                <DropdownSearch
                    key={`generationMark-${search.keyCounter}`}
                    items={device.types}
                    name={"Марка"}
                    className={`w-full ${
                        device.types?.length > 0
                            ? ""
                            : "pointer-events-none opacity-50"
                    }`}
                    fullWidth={true}
                    onChangeCallback={(selectedTypeValue, selectedTypeName) => {
                      setMarks(selectedTypeValue, device, search);
                      setMarkValidate(selectedTypeName)
                    }}
                    tagStyle={
                      (isFieldPreparing || markValidate) === "" ? VALIDATE_STYLE : {}
                    }
                />
                <DropdownSearch
                    key={`brandsDropdown-${search.stamp + search.keyCounter}`}
                    items={device.brands}
                    name={"Модель"}
                    className={`w-full ${
                        device.brands?.length > 0
                            ? ""
                            : "pointer-events-none opacity-50"
                    }`}
                    fullWidth={true}
                    isPageShop={true}
                    type={true}
                    onChangeCallback={(selectedTypeValue, selectedTypeName) => {
                      setBrands(selectedTypeValue, device, search);
                      setBrandValidate(selectedTypeName)
                    }}
                    tagStyle={
                      (isFieldPreparing || brandValidate) === "" ? VALIDATE_STYLE : {}
                    }
                />
                <Dropdown
                    key={`generationDropdown-${
                        search.stamp + search.model + search.keyCounter
                    }`}
                    items={device.generations}
                    name={"Поколение"}
                    className={`w-full ${
                        device.generations?.length > 0
                            ? ""
                            : "pointer-events-none opacity-50"
                    }`}
                    fullWidth={true}
                    isPageShop={true}
                    type={true}
                    onChangeCallback={(selectedTypeValue, selectedTypeName) => {
                      setGeneration(selectedTypeValue, search, device);
                      setGenerationValidate(selectedTypeName)
                    }}
                    tagStyle={
                      (isFieldPreparing || generationValidate) === "" ? VALIDATE_STYLE : {}
                    }
                />
                <Dropdown
                    key={`generationCategories-${search.keyCounter}`}
                    items={device.categories}
                    name={"Категория"}
                    className={`w-full ${
                        device.categories?.length > 0
                            ? ""
                            : "pointer-events-none opacity-50"
                    }`}
                    fullWidth={true}
                    isPageShop={true}
                    type={true}
                    onChangeCallback={(selectedTypeValue) => {
                      setCategories(selectedTypeValue, search, device);
                      setCategoriesValidate(selectedTypeValue)
                    }}
                    tagStyle={
                      (isFieldPreparing || categoriesValidate) === "" ? VALIDATE_STYLE : {}
                    }
                />
                <Dropdown
                    key={`brandsCategoriesTypes-${
                        search.category + search.keyCounter
                    }`}
                    items={device.categoriesTypes}
                    name={"Тип"}
                    className={`w-full ${
                        device.categoriesTypes?.length > 0
                            ? ""
                            : "pointer-events-none opacity-50"
                    }`}
                    fullWidth={true}
                    isPageShop={true}
                    type={true}
                    onChangeCallback={(selectedTypeValue, selectedTypeName) => {
                      setCategoriesTypes(selectedTypeValue, search, device);
                      setCategoriesTypesValidate(selectedTypeName)
                    }}
                    tagStyle={
                      (isFieldPreparing || categoriesTypesValidate) === "" ? VALIDATE_STYLE : {}
                    }
                />

                <input
                    className="flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none"
                    placeholder="Адрес"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    style={
                      (isFieldPreparing || address) === "" ? VALIDATE_STYLE : {}
                    }
                />
              </div>

              <div className="xl:w-1/2 xl:pl-3">
                {/*<FileUpload onFileSelect={file => setImage(file)} />*/}
                <AddPhoto 
                  setImage={setImage} 
                  image={image}
                  photos={photos}
                  setPhotos={setPhotos}
                  tagStyle={
                    (isFieldPreparing || image.length) === 0 ? VALIDATE_STYLE : {}
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          {
            !isDetailCreated ? 
            <>
              <button
                  type="button"
                  className="button"
                  onClick={() => resetFormProducts(search, device)}
              >
                Сбросить
              </button>
              <button
                  className="button button-accent"
                  onClick={(e) =>
                      createProduct(
                          e,
                          price,
                          address,
                    title,
                    description,
                    search.generation,
                    search.type,
                    image
                  )
                }
              >
                Создать деталь
              </button>
            </>
            :
            <>
              <button
                  type="button"
                  className="button"
                  onClick={() => history(MAIN_ROUTE)}
              >
                На главную
              </button>
              <button
                  className="button button-accent"
                  onClick={(e) => history(USER_PROFILE_ADS)}
              >
                Мои объявления
              </button>
            </>

          }

        </div>
      </form>

      <ErrorAlert
        open={errorAlertShow}
        setOpen={setErrorAlertShow}
        title={alertTitle}
        status={errorStatus}
        message={alertMessage}
      />
      <AccessAlert
        open={accessAlertShow}
        setOpen={setAccessAlertShow}
        alertTitle={alertTitle}
        alertMessage={alertMessage}
      />
    </div>
  );
});

export default CogForm;
