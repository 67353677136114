import { useEffect, useState } from "react";

export const useScreenSize = (screenWidth) => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < screenWidth);

  useEffect(() => {
    let prevIsMobile = isMobileScreen;

    const handleResize = () => {
      const currentIsMobile = window.innerWidth < screenWidth;
      if (currentIsMobile !== prevIsMobile) {
        setIsMobileScreen(currentIsMobile);
        prevIsMobile = currentIsMobile;
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileScreen]);

  return isMobileScreen;
};
