import { AtSign, AtomIcon, Eye, Home, Phone } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../..'
import { fetchRegions } from '../../../http/locationAPI'
import { sendSms } from '../../../http/userAPI'
import {
	CHANGE_PASSWORD,
	LOGIN_ROUTE,
	MAILING_ROUTE,
	PRIVACY_POLICY_ROUTE,
	REGISTRATION_ROUTE,
	VALIDATE_STYLE,
} from '../../../utils/consts'
import Field from '../../ui/Field/Field'
import AccessAlert from '../../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../../ui/modal/ErrorAlert/ErrorAlert'
import PhoneVerification from '../../ui/modal/PhoneVerification/PhoneVerification'
import { handleAuth } from './AuthUtils'

const AuthPage = observer(() => {
	const { user, location } = useContext(Context)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [jwtToken, setJwtToken] = useState('')
	// const isLocationAdmin = location.pathname === ADMIN_ROUTE
	const currentLocation = useLocation()
	const isLogin = currentLocation.pathname === LOGIN_ROUTE
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [city, setCity] = useState(null)
	const [cityRepresentation, setCityRepresentation] = useState('')
	const [phone, setPhone] = useState('')
	const [confirmedPhone, setConfirmedPhone] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
	const [verificationShow, setVerificationShow] = useState(false)
	const [verificationId, setVerificationId] = useState('')
	const [isFieldPreparing, setIsFieldPreparing] = useState(true)

	const [isValidPassword, setIsValidPassword] = useState(false)
	const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
		'В пароле должны быть цифры и буквы',
	)

	const navigate = useNavigate()

	useEffect(() => {
		fetchRegions({ name: cityRepresentation }).then(data => {
			location.setLocations(data)
		})
	}, [cityRepresentation, location])

	useEffect(() => {
		setIsFieldPreparing(true)
	}, [currentLocation])

	useEffect(() => {
		if (user.isAuth) {
			navigate('/')
		}
	}, [navigate, user.isAuth])

	const onChangePassword = (passwordValue, isFormCompleted = null) => {
		setPassword(passwordValue)
		const isDigits = /\d/.test(passwordValue)
		const isLetters = /[a-zA-Zа-яА-Я]/.test(passwordValue)
		const isFieldPreparingLocal = isFormCompleted === null ? isFieldPreparing : isFormCompleted
		if (!isFieldPreparingLocal) {
			if (!isDigits && !isLetters) {
				setIsValidPassword(false)
				setInvalidPasswordMessage('В пароле должны быть цифры и буквы')
				return false
			} else if (!isDigits) {
				setIsValidPassword(false)
				setInvalidPasswordMessage('В пароле должны быть цифры')
				return false
			} else if (!isLetters) {
				setIsValidPassword(false)
				setInvalidPasswordMessage('В пароле должны быть буквы')
				return false
			} else {
				setIsValidPassword(true)
				return true
			}
		} else {
			setIsValidPassword(true)
			return true
		}
	}

	const click = async e => {
		e.preventDefault()
		setIsFieldPreparing(false)
		const isValidPasswordLocal = onChangePassword(password, false)
		if (email && password && isValidPasswordLocal) {
			e.preventDefault()
			await loginOrRegister(jwtToken)
		}
	}

	const loginOrRegister = async token => {
		setJwtToken(token)
		setConfirmedPhone(phone)
		await handleAuth(
			email,
			password,
			name,
			city,
			token,
			isLogin,
			user,
			setAlertMessage,
			setErrorStatus,
			setAlertTitle,
			setErrorAlertShow,
			setAccessAlertShow,
			navigate,
		)
	}

	const smsVerification = async e => {
		e.preventDefault()
		setIsFieldPreparing(false)
		const isValidPasswordLocal = onChangePassword(password, false)
		if (email && password && isValidPasswordLocal && name && cityRepresentation && phone) {
			if (jwtToken && confirmedPhone === phone) {
				return await loginOrRegister(jwtToken)
			}
			try {
				const response = await sendSms(phone.replace(/\s/g, ''))
				setVerificationId(response.data.verification_id)
				setVerificationShow(true)
			} catch (error) {
				console.error(error)
				setAlertMessage(error.response)
				setErrorStatus(error.response?.status)
				setAlertTitle('Ошибка отправки смс')
				setErrorAlertShow(true)
			}
		}
	}

	return (
		<div className='flex justify-center'>
			<form className={`border border-border ${isLogin ? 'pt-10' : ''} pb-10`}>
				{!isLogin ? (
					<h1 className='text-center mb-10'>Регистрация</h1>
				) : (
					<h1 className='text-center mb-10'>Войти</h1>
				)}
				<div className='flex flex-col justify-center'>
					<Field
						required={true}
						placeholder='Ввести email'
						className='mb-7 bg-grey p-3 rounded-2xl w-full'
						type='email'
						name='email'
						Icon={AtSign}
						value={email}
						onChange={e => setEmail(e.target.value)}
						full='w-full'
						style={(isFieldPreparing || email) === '' ? VALIDATE_STYLE : {}}
					/>
					{!isLogin ? (
						<>
							<Field
								required={true}
								placeholder='Ввести имя'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='name'
								name='name'
								Icon={AtomIcon}
								value={name}
								onChange={e => setName(e.target.value)}
								full='w-full'
								style={(isFieldPreparing || name) === '' ? VALIDATE_STYLE : {}}
							/>
							<Field
								required={true}
								placeholder='Ваш город'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='city'
								clearField={() => {
									setCityRepresentation('')
								}}
								Icon={Home}
								value={cityRepresentation}
								func={(city_id, city_repr) => {
									setCity(city_id)
									setCityRepresentation(city_repr)
								}}
								onChange={e => setCityRepresentation(e.target.value)}
								items={location.locations}
								full='w-9/12'
								style={
									(isFieldPreparing || cityRepresentation) === ''
										? VALIDATE_STYLE
										: {}
								}
							/>

							<Field
								required={true}
								placeholder='Ваш номер телефона'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='tel'
								name='phone'
								Icon={Phone}
								value={phone}
								onChange={e => setPhone(e.target.value)}
								full='w-full'
								is_confirmed={jwtToken && confirmedPhone === phone}
								style={(isFieldPreparing || phone) === '' ? VALIDATE_STYLE : {}}
							/>
						</>
					) : (
						''
					)}
					<Field
						required={true}
						placeholder='Ввести пароль'
						error={{ message: '', type: 'min' }}
						className={`${
							!isFieldPreparing && !isValidPassword ? 'mb-1' : 'mb-7'
						} bg-grey p-3 rounded-2xl w-80 flex`}
						type='password'
						name='password'
						Icon={Eye}
						value={password}
						onChange={e => {
							onChangePassword(e.target.value)
						}}
						isLogin={false}
						style={!isFieldPreparing && !isValidPassword ? VALIDATE_STYLE : {}}
					/>
					{!isFieldPreparing && !isValidPassword ? (
						<span className='ml-5 mb-3 text-md text-red-600'>
							{invalidPasswordMessage}
						</span>
					) : (
						<></>
					)}

					{!isLogin ? (
						<div className='text-center max-w-[300px] mb-7'>
							<p className='text-[12px] leading-[14px]'>
								Отправляя свои данные, вы даете разрешение связаться с вами по
								телефону или с помощью мессенджера. Вы так же даете согласие на
								обработку персональных данных и соглашаетесь c{' '}
								<a className='link-accent' href={PRIVACY_POLICY_ROUTE}>
									политикой конфединциальности
								</a>
								,{' '}
								<a className='link-accent' href={MAILING_ROUTE}>
									политикой рассылки
								</a>
								.
							</p>
						</div>
					) : (
						''
					)}

					{!isLogin ? (
						<>
							<div className='mb-4 text-center'>
								Есть аккаунт?{' '}
								<NavLink className='link-accent' to={LOGIN_ROUTE}>
									Войти!
								</NavLink>
							</div>
						</>
					) : (
						<>
							<div className='mb-4 text-center'>
								Нет аккаунта?{' '}
								<NavLink className='link-accent' to={REGISTRATION_ROUTE}>
									Зарегистрируйся!
								</NavLink>
							</div>
							<div className='mb-7 text-center'>
								Забыл пароль?{' '}
								<NavLink className='link-accent' to={CHANGE_PASSWORD}>
									Восстановить пароль
								</NavLink>
							</div>
						</>
					)}

					{!isLogin ? (
						<div className='text-center'>
							<button
								className='accent button button-accent'
								onClick={smsVerification}>
								Зарегистрироваться
							</button>
						</div>
					) : (
						<div className='text-center'>
							<button className='accent button button-accent' onClick={click}>
								Войти
							</button>
						</div>
					)}
				</div>
			</form>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
				isLogin={isLogin}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>

			<PhoneVerification
				open={verificationShow}
				setOpen={setVerificationShow}
				verificationId={verificationId}
				handleRegister={loginOrRegister}
			/>
		</div>
	)
})

export default AuthPage
