import React from "react";
import styles from "./Slider.module.scss";

const SliderButton = ({ func, direction, customStyles = {} }) => {
    return (
        <button
            onClick={func}
            className={direction === -1 ? `${styles.arrowButtonLeft}`: `${styles.arrowButtonRight}`}
            style={customStyles}
        >
            <svg
            width="38px"
            height="38px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={direction == -1 ? "rotate(-135)": "rotate(-315)"}
            stroke="#ffffff"
            >
            <g id="SVGRepo_bgCarrier" stroke-width="0" />

            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
            />


            <g id="SVGRepo_iconCarrier">
                {" "}
                <g clip-path="url(#clip0_429_11220)">
                {" "}
                <path
                    d="M16 16L16 8L8 8"
                    className={styles.arrowSvg}
                    stroke="#000000"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />{" "}
                </g>{" "}
                <defs>
                {" "}
                <clipPath id="clip0_429_11220">
                    {" "}
                    <rect width="24" height="24" fill="white" />{" "}
                </clipPath>{" "}
                </defs>{" "}
            </g>
            </svg>
        </button>
    )
}

export default SliderButton;