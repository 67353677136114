import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import { VALIDATE_STYLE } from '../../utils/consts'
import { sendComplain } from '../../http/userAPI';
import AccessAlert from '../ui/modal/AccessAlert/AccessAlert';


export default function WriteComplain({ open, setOpen, alertTitle, submitTitle, productId }) {
	const cancelButtonRef = useRef(null)
	const [isFieldPreparing, setIsFieldPreparing] = useState(true);
	const [complainMessage, setComplainMessage] = useState("");
	const [accessAlertShow, setAccessAlertShow] = useState(false)

	const handleSendComplain = async () => {
		setIsFieldPreparing(false)
		try {
			if (complainMessage) {
				const data = await sendComplain(productId, complainMessage)
				setOpen(false)
				setIsFieldPreparing(true)
				setComplainMessage("")
				setAccessAlertShow(true)
			}
		} catch {
			setOpen(false)
		}
	}

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
									<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
										<div className='flex flex-col items-center gap-3'>
											<div className='text-center ml-4 sm:mt-0 sm:text-left'>
												<Dialog.Title
													as='h3'
													className='text-base font-semibold leading-6 text-gray-900'>
													{alertTitle}
												</Dialog.Title>
											</div>
											<textarea
												className="flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none"
												placeholder="Напишите сюда вашу жалобу"
												value={complainMessage}
												onChange={(e) => setComplainMessage(e.target.value)}
												style={
													(isFieldPreparing || complainMessage) === "" ? VALIDATE_STYLE : {}
												}
											/>
										</div>
									</div>
									<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
										<button
											type='button'
											className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
											onClick={() => {
												handleSendComplain()
											}}
											ref={cancelButtonRef}
										>
											{submitTitle}
										</button>
										<button
											type='button'
											className='mt-3 inline-flex w-full justify-center outline-none rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto hover:bg-accent-300'
											onClick={() => setOpen(false)}
											ref={cancelButtonRef}
										>
											Отменить
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle="Ваша жалоба успешно отправлена"
			/>
		</>
	)
}
