// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbar__kSz7H {
  display: flex;
  justify-content: space-between;
  padding-top: 37px;
  padding-bottom: 50px;
}

.Navbar_notificationText__5x2Qs {
  padding-top: 20px;
  max-height: 63px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACC,iBAAA;EACA,gBAAA;EACA,gBAAA;AACD","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-between;\n  padding-top: 37px;\n  padding-bottom: 50px;\n}\n\n.notificationText {\n\tpadding-top: 20px;\n\tmax-height: 63px;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Navbar_navbar__kSz7H`,
	"notificationText": `Navbar_notificationText__5x2Qs`
};
export default ___CSS_LOADER_EXPORT___;
