import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import styles from "./DropdownSearch.module.scss";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const DropdownSearch = observer(
    ({
        items = [],
        className,
        name = "",
        fullWidth = false,
        isPageShop = false,
        onChangeCallback,
        selectedId = null,
        isCityChoose = false,
        value = ''
    }) => {
        const [optionsTitle, setOptionsTitle] = useState(name);
        const [searchQuery, setSearchQuery] = useState("");
        const [filteredItems, setFilteredItems] = useState(items);

        useEffect(() => {
            const selectedOption = items.find((item) => item.id === selectedId);
            if (selectedOption) {
                if (isCityChoose) {
                    setOptionsTitle(selectedOption.region.name + " " + selectedOption.name);
                } else {
                    setOptionsTitle(selectedOption.name);
                }
            }
        }, [selectedId, items]);

        useEffect(() => {
            setFilteredItems(
                items.filter((item) =>
                    item.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }, [searchQuery, items]);

        function handleOnChange(typeName, value = "") {
            setOptionsTitle(typeName);
            if (onChangeCallback) {
                onChangeCallback(value, typeName);
            }
        }

        return (
            <Menu
                as="div"
                className={`relative inline-block text-left ${className}`}
            >
                {isPageShop ? (
                    <div>
                        <Menu.Button
                            className="button flex justify-between gap-x-2 w-full items-center"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            {value !== '' ? value : optionsTitle}
                            <ChevronDownIcon
                                className="-mr-1 h-5 w-5"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                ) : (
                    <div className="mt-3 rounded-sm">
                        <Menu.Button className="flex outline-none p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between">
                            {value !== '' ? value : optionsTitle}
                            <ChevronDownIcon
                                className="-mr-1 h-5 w-5"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                )}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className={`absolute left-0 z-10 mt-2 origin-top-right color-[#000] rounded-md bg-grey shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                            fullWidth ? "w-full" : ""
                        }`}
                    >
                    <div className="p-2 flex flex-row items-center relative">
                        <input
                            type="text"
                            className={`${styles.searchInput} w-full p-2 placeholder-gray-700 rounded-md h-9 text-sm border border-gray-300 focus:border-black focus:ring-0 outline-none`}
                            placeholder="Поиск..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {
                            searchQuery &&
                            <svg
                                onClick={() => {setSearchQuery("")}}
                                className="absolute right-4 cursor-pointer hover:bg-gray-200 rounded-lg"
                                style={{ top: "15px" }}
                                width="23px"
                                height="23px"
                                viewBox="0 0 24.00 24.00"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <g id="crossCleanInput">
                                    {" "}
                                    <path
                                        d="M16 8L8 16M8.00001 8L16 16"
                                        stroke="#000000"
                                        stroke-width="1.7"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />{" "}
                                </g>
                            </svg>
                        }
                    </div>
                        <div
                            className="py-1 overflow-x-hidden overflow-y-scroll"
                            style={{ maxHeight: "364px" }}
                        >
                            {filteredItems?.map((type) => (
                                <Menu.Item key={type.id}>
                                    {({ active }) => (
                                        <div
                                            onClick={() => {
                                                if (isCityChoose) {
                                                    handleOnChange(
                                                        type.region.name + " " + type.name,
                                                        type.id ? type.id : ""
                                                    )
                                                }   else {
                                                    handleOnChange(
                                                        type.name,
                                                        type.id ? type.id : ""
                                                    )
                                                }
                                            }
                                            }
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-accent"
                                                    : "text-[#000]",
                                                "block px-4 py-2 text-sm cursor-pointer"
                                            )}
                                        >
                                            {isCityChoose ? (type.region.name + " " + type.name) : type.name}
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    }
);

export default DropdownSearch;