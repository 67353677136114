// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gear-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gear {
    position: absolute;
    animation: blast 1.5s forwards;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    font-size: 1rem;
    animation-timing-function: ease-in;
}

@keyframes blast {
    0% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
    }
    30% {
        transform: translate(var(--x), var(--y)) rotate(600deg);
        opacity: 1;
    }
    100% {
        transform: translate(var(--x), 100%) rotate(1200deg);
        opacity: 0;
    }
}

.gear-0 { --x: 10px; --y: -20px; }
.gear-1 { --x: -15px; --y: -10px; }
.gear-2 { --x: 20px; --y: -5px; }
.gear-3 { --x: -10px; --y: -15px; }
.gear-4 { --x: 15px; --y: 10px; }
.gear-5 { --x: -20px; --y: 5px; }
.gear-6 { --x: 10px; --y: 15px; }
.gear-7 { --x: -5px; --y: 20px; }
.gear-8 { --x: 5px; --y: -10px; }
.gear-9 { --x: -10px; --y: 10px; }`, "",{"version":3,"sources":["webpack://./src/components/screens/game/GearAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,8BAA8B;IAC9B,uBAAuB;IACvB,wBAAwB;IACxB,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI;QACI,uCAAuC;QACvC,UAAU;IACd;IACA;QACI,uDAAuD;QACvD,UAAU;IACd;IACA;QACI,oDAAoD;QACpD,UAAU;IACd;AACJ;;AAEA,UAAU,SAAS,EAAE,UAAU,EAAE;AACjC,UAAU,UAAU,EAAE,UAAU,EAAE;AAClC,UAAU,SAAS,EAAE,SAAS,EAAE;AAChC,UAAU,UAAU,EAAE,UAAU,EAAE;AAClC,UAAU,SAAS,EAAE,SAAS,EAAE;AAChC,UAAU,UAAU,EAAE,QAAQ,EAAE;AAChC,UAAU,SAAS,EAAE,SAAS,EAAE;AAChC,UAAU,SAAS,EAAE,SAAS,EAAE;AAChC,UAAU,QAAQ,EAAE,UAAU,EAAE;AAChC,UAAU,UAAU,EAAE,SAAS,EAAE","sourcesContent":[".gear-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}\n\n.gear {\n    position: absolute;\n    animation: blast 1.5s forwards;\n    top: calc(50% - 0.5rem);\n    left: calc(50% - 0.5rem);\n    font-size: 1rem;\n    animation-timing-function: ease-in;\n}\n\n@keyframes blast {\n    0% {\n        transform: translate(0, 0) rotate(0deg);\n        opacity: 1;\n    }\n    30% {\n        transform: translate(var(--x), var(--y)) rotate(600deg);\n        opacity: 1;\n    }\n    100% {\n        transform: translate(var(--x), 100%) rotate(1200deg);\n        opacity: 0;\n    }\n}\n\n.gear-0 { --x: 10px; --y: -20px; }\n.gear-1 { --x: -15px; --y: -10px; }\n.gear-2 { --x: 20px; --y: -5px; }\n.gear-3 { --x: -10px; --y: -15px; }\n.gear-4 { --x: 15px; --y: 10px; }\n.gear-5 { --x: -20px; --y: 5px; }\n.gear-6 { --x: 10px; --y: 15px; }\n.gear-7 { --x: -5px; --y: 20px; }\n.gear-8 { --x: 5px; --y: -10px; }\n.gear-9 { --x: -10px; --y: 10px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
