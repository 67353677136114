import { useEffect } from 'react'

export const useScrollArrow = setIsShowArrowTop => {
	useEffect(() => {
		const handleScroll = () => {
			setIsShowArrowTop(window.scrollY > 200)
		}
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [setIsShowArrowTop])
}

export const usePaginationLogic = (totalPages, currentPage, isMobileScreen) => {
	const renderEllipsis = key => (
		<li
			style={{ cursor: 'default' }}
			key={key}
			className='flex flex-col justify-end list-none mx-1.5 h-9 translate-y-1'>
			<span className='cursor-default'>...</span>
		</li>
	)

	const renderPageButton = (page, isActive, onClick) => (
		<li key={page} className='list-none mx-1.5'>
			<button
				onClick={() => onClick(page)}
				className={`px-3 py-1.5 rounded-md cursor-pointer tr h-9 test
          ${isActive ? 'bg-accent text-white' : 'bg-grey/30 text-black'} 
          hover:bg-accent hover:text-white`}>
				{page}
			</button>
		</li>
	)

	const getPageNumbers = (isMobileScreen, onClick) => {
		const pageNumbers = []

		if (isMobileScreen ? totalPages <= 4 : totalPages <= 6) {
			for (let i = 1; i <= totalPages; i++) {
				pageNumbers.push(renderPageButton(i, currentPage === i, onClick))
			}
		} else {
			if (isMobileScreen ? currentPage <= 2 : currentPage <= 3) {
				for (let i = 1; i <= (isMobileScreen ? 2 : 4); i++) {
					pageNumbers.push(renderPageButton(i, currentPage === i, onClick))
				}
				pageNumbers.push(renderEllipsis('right'))
				pageNumbers.push(renderPageButton(totalPages, false, onClick))
			} else if (isMobileScreen ? currentPage > totalPages - 2 :currentPage > totalPages - 3) {
				pageNumbers.push(renderPageButton(1, false, onClick))
				pageNumbers.push(renderEllipsis('left'))
				for (let i = totalPages - (isMobileScreen ? 1 : 3); i <= totalPages; i++) {
					pageNumbers.push(renderPageButton(i, currentPage === i, onClick))
				}
			} else {
				pageNumbers.push(renderPageButton(1, false, onClick))
				pageNumbers.push(renderEllipsis('left'))
				for (let i = (isMobileScreen ? currentPage : currentPage - 1); i <= (isMobileScreen ? currentPage : currentPage + 1); i++) {
					pageNumbers.push(renderPageButton(i, currentPage === i, onClick))
				}
				pageNumbers.push(renderEllipsis('right'))
				pageNumbers.push(renderPageButton(totalPages, false, onClick))
			}
		}

		return pageNumbers
	}

	return { getPageNumbers }
}
